import * as types from './actionTypes';
import axios from 'axios';
import * as api from '../../api';
import catchRequestError from '../../commonExtensions/catchRequestError';
import { url as apiUrl } from '../../api'
import { ShowAlert } from '../../components/Alert/AlertAction'
import { Axiosinstance, checkAvailableHeader } from '../../api';


function parseJwt (token) {
	if (token) {
	  var base64Url = token.split('.')[1];
	var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
		return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));
  
	return JSON.parse(jsonPayload);
	}
	else {
	  return null
	}
	
  }


export function LoginInServer(id) {

	return dispatch => {
		return (

			axios.post(apiUrl + `/api/v1/accounts/signin`, id, api.headerData
			).then(function (response) {
				console.log(response.data)
				localStorage.setItem('token', response.data.accessToken);
				localStorage.setItem('refreshToken', response.data.refreshToken);
				localStorage.setItem('isLogin', 'true');

				console.log('token from back:', parseJwt(response.data.accessToken))
				// if (id?.userName) {
				// 	localStorage.setItem('userName', id.userName); // delete when whoami req will be ready
				// }
				Axiosinstance.defaults.headers = checkAvailableHeader({ auth: true })
				dispatch(whoami()) // uncoment when whoami req will be ready

				return 'ok'

			})
				.catch((error) => {
					catchRequestError(error, dispatch);

				})

		)
	}
}

export function LogoutFromServer(id) {

	return dispatch => {
		return (
			console.log(api.headerData),
			Axiosinstance.post(apiUrl + `/api/v1/accounts/signout`, {}).then(function (response) {
				localStorage.setItem('userName', '');
				return 'ok'

			})
				.catch((error) => {
					catchRequestError(error, dispatch);

				})
		)
	}
}




export function RegistrInServer(id) {

	return dispatch => {
		return (

			axios.post(apiUrl + `/api/v1/accounts/signup`, id, api.headerData
			).then(function (response) {
				localStorage.setItem('token', response.data.accessToken);
				localStorage.setItem('refreshToken', response.data.refreshToken);
				localStorage.setItem('isLogin', 'true');
				// if (id?.userName) {
				// 	localStorage.setItem('userName', id.userName); // delete when whoami req will be ready
				// }
				
				Axiosinstance.defaults.headers = checkAvailableHeader({ auth: true })
				dispatch(whoami()) // uncoment when whoami req will be ready

				return 'ok'

			})
				.catch((error) => {
					catchRequestError(error, dispatch);

				})
		)
	}
}

export function RegistrInServerNew(id) {

	console.log(id)

	

	return dispatch => {
		return (

			Axiosinstance.post(apiUrl + `/api/v1/accounts/signup`, id).then(function (response) {
				dispatch(whoami()) // uncoment when whoami req will be ready
				return 'ok'

			})
				.catch((error) => {
					catchRequestError(error, dispatch);

				})
		)
	}
}

export function changePassword(params) {

	return dispatch => {
		return (

			Axiosinstance.post(apiUrl + `/api/v1/accounts/change_password`, params).then(function (response) {
				dispatch(ShowAlert('success', 'Success', false, false));
				return 'ok'

			})
				.catch((error) => {
					catchRequestError(error, dispatch);

				})
		)
	}
}

export function changeUserInfo(params) {

	return dispatch => {
		return (

			Axiosinstance.post(apiUrl + `/api/v1/accounts/change_info`, params).then(function (response) {
				dispatch(ShowAlert('success', 'Success', false, false));
				dispatch(whoami())
				return 'ok'

			})
				.catch((error) => {
					catchRequestError(error, dispatch);

				})
		)
	}
}

export function savePublickey(key) {

	return dispatch => {
		return (
			//dispatch(showLoading()),
			Axiosinstance.post(apiUrl + `/api/v1/accounts/public_keys`, { key: key }).then(function (response) {

				console.log('fine')
				return response.data;

			})
				.catch((error) => {
					catchRequestError(error, dispatch);
				})
		)
	}
}

export function get_link(key) {

	return dispatch => {
		//	createAuthRefreshInterceptor(axios, refreshAuthLogic);
		return (
			//dispatch(showLoading()),
			Axiosinstance.get(`/api/v1/downloads/get_link`).then(function (response) {

				let data = {
					link: response.data.link
				}
				return data

			})
				.catch((error) => {
					catchRequestError(error, dispatch);
				})
			//	createAuthRefreshInterceptor(axios, refreshAuthLogic)
		)
	}
}

export function getNewToken(key) {
	return dispatch => {
		return (
			Axiosinstance.get(`/api/v1/Accounts/token`).then(function (response) {
				return response.data.token

			})
				.catch((error) => {
					catchRequestError(error, dispatch);
				})
		)
	}
}


export function resetUserPassword(id) {
	return dispatch => {
		return (
			Axiosinstance.post(`/api/v1/Accounts/reset_password`,{login:id}).then(function (response) {
				return response.data.password

			})
				.catch((error) => {
					catchRequestError(error, dispatch);
				})
		)
	}
}





export const setWHoAmI = payload => ({
	type: types.WHO_AM_I_DATA,
	payload
  });

export function whoami(id) {

	return dispatch => {
		return (
			console.log('who am i token:',parseJwt(Axiosinstance.defaults.headers.Authorization.substring(7))),
			Axiosinstance.get(apiUrl + `/api/v1/whoami`).then(function (response) {

				localStorage.setItem('userName', response.data.userName);
				dispatch(setWHoAmI(response.data));
				return response.data

			})
				.catch((error) => {
					catchRequestError(error, dispatch);

				})

		)
	}
}



