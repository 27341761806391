import { createSelector } from 'reselect';

import { convertBytes } from '../../commonExtensions/bytesParser';
import { stringToLowerCase } from '../../commonExtensions/common';
import { timeParserUnixToDate } from '../../commonExtensions/timeParser';

import locale from '../../locale';

const users = state => state.users.users;
const whoAmI = state => state.settings.who_am_i;

export const getUsersTableData = createSelector(users, whoAmI, (value, whoAmIData) => {
  class UsersTableBody {
    constructor (data) {
      this.id = data.login;
      this.userName = data.userName;
      this.email = data.email;
      this.role = data.role[0]
      this.fullData = data;
    }
  };

  const currentUserId = value?.find(item => item?.login === whoAmIData?.login)?.login || '';

  return {
    header: [
      {
        column: 'userName',
        sort: 'userName',
        title: 'Name',
        style: { width: '30%' },
      },
      {
        column: 'email',
        sort: 'email',
        title: 'e-mail',
      },
      {
        column: 'role',
        sort: 'role',
        title: 'role',
      },
    ],
    body: Array.isArray(value) && value.map(item => new UsersTableBody(item)),
    disabled: currentUserId ? [currentUserId] : [], 
    config: {
      type: 'users',
      selectable: true,
    },
  };
});


