import React, { useState, useEffect } from 'react';
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
} from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';

import Login from '../Login';
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header';
import Alert from '../../components/Alert/Alert'
import ChangePasswordModal from '../../components/ChangePasswordModal';

import routes from '../../routes';

import {
  onDestroySession,
  LoginInServer,
  RegistrInServer,
  LogoutFromServer,
  whoami
} from '../../store/actions';

import {getWhoAmI} from '../../store/selectors'

import './styles.scss';

const getChildrenRoutes = (childArr) => {
  if (Array.isArray(childArr)) {
    return childArr.map(route => (
      <Route
        key={Date.now()}
        path={route.path}
        element={<route.element />}
      >
        {route?.children && this.getChildrenRoutes(route.children)}
      </Route>
    ))
  }

  return null;
}

// A special wrapper for <Route> that knows how to
// handle 'sub'-routes by passing them in a `routes`
// prop to the component it renders.
const routeWithSubRoutes = (route) => {
  if (route?.path === '/') {
    return (
      <Route
        key={route.path}
        path={route.path}
        element={<Navigate replace to='/account' />}
      >
        {route?.children && getChildrenRoutes(route.children)}
      </Route>
    );
  }

  return (
    <Route
      key={route.path}
      path={route.path}
      element={<route.element />}
    >
      {route?.children && getChildrenRoutes(route.children)}
    </Route>
  );
}

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [stateLoginStatus, setStateLoginStatus] = useState('loading');
  const [showTempPassWindow, setShowTempPassWindow] = useState(true);
  const whoAmI = useSelector(getWhoAmI);

  useEffect(() => {


    let params = window.location.search;
    params = new URLSearchParams(window.location.search);

    if (params.has('token')) {

      let token = params.get('token');
      let name = params.get('userName');
      localStorage.setItem('userName', name)
      window.history.pushState({}, null, window.location.origin);
      onLoginBtnClick({token})
      return;
    }

    

    if (localStorage.getItem('isLogin') === 'true') {
      setStateLoginStatus('login');
      dispatch(whoami())
    }

    if (localStorage.getItem('isLogin') !== 'true') {
      setStateLoginStatus('logout');
    }

  }, []);

  const onLoginBtnClick = (data) => {
    if (data) {
      console.log(data)
      //  localStorage.setItem('isLogin', true);
      dispatch(LoginInServer(data)).then((status) => {
        if (status === 'ok') {
          setStateLoginStatus('login');
        }
      })
    }
  }

  const onRegisterBtnClick = (data) => {
    if (data) {
      dispatch(RegistrInServer(data)).then((status) => {
        if (status === 'ok') {
          setStateLoginStatus('login');
        }
      })
    }
  }

  const onLogoutBtnClick = () => {

    dispatch(LogoutFromServer()).then((data) => {
      if (data === 'ok') {
        localStorage.setItem('isLogin', false);
        localStorage.setItem('token', '');
        localStorage.setItem('refreshToken', '');
        setStateLoginStatus('logout');
        dispatch(onDestroySession());
        navigate('/', { replace: true });
      }

    })

  }

  return (

    <>

      {stateLoginStatus === 'loading' && <div>
        loading
      </div>}


      {stateLoginStatus === 'login' &&
        <div className='app__wrapper'>
          <aside className='app__aside_wrapper'>
            <Sidebar routes={routes} />
          </aside>
          <div className='app__content_wrapper'>
            <div className='app__header_wrapper'>
              <Header logout={onLogoutBtnClick} />
            </div>
            {/* <button onClick={onLogoutBtnClick}>LOGOUT</button> */}
            <main className='app__main_wrapper'>
              <Routes>
                {routes.map(routeWithSubRoutes)}
              </Routes>
            </main>
          </div>
          <Alert />
          {whoAmI?.isPasswordTemporary && showTempPassWindow ? <ChangePasswordModal onClose={()=> setShowTempPassWindow(false)}/> : null}
        </div>}
        {stateLoginStatus === 'logout' &&
        <Login
          warningText={ ''}
          onLoginBtnClick={onLoginBtnClick}
          onRegisterBtnClick={onRegisterBtnClick}
        />}
      <Alert />
    </>
  )
}

export default App;
