// flow

import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import isEqual from "lodash.isequal";
import Loader from "../../components/Loader";
import Checkbox from "../../components/Checkbox";
import { setTableSelectedRowData } from "../../store/actions";

import "./styles.scss";

type Props = {
  data: any,
  config?: {
    checkbox?: boolean,
  },
  onCheckboxSelect?: (data: Array<any>) => void,
};

const Table = ({ data, config, onCheckboxSelect }: Props) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    if (data?.selected && !isEqual(selected, data.selected)) {
      setSelected(data.selected);
      setSelectedIds(data.selected.map((item) => item?.id));
    }
  }, [data]);

  const onTableRowClick = ({ e, selectedItem }) => {
    if (data?.config?.selectable) {
      dispatch(setTableSelectedRowData(selectedItem));

      // if (data?.config?.type === 'files') {

      //   navigate(`${selectedItem?.[data?.keyId] || selectedItem?.id || ''}`, { replace: true });
      // }
    } else {
      e.preventDefault();
    }
  };

  const convertDownloadState = (progress) => {
    if (progress == 0) {
      return "Cloud";
    }
    if (progress == 100) {
      return "Local & Cloud";
    }
    return (
      <div className="wrap-loader">
        <Loader />
        <div>{progress}% Downloaded...</div>{" "}
      </div>
    );
  };

  const getAlign = (column) => {
    if (column === "size") return "table__value_align_right";

    return "";
  };

  const onSelectAll = () => {
    const tableBodyArr = Array.isArray(data?.body) ? data.body : [];
    const disabledArr = Array.isArray(data?.disabled) ? data.disabled : [];

    if (
      selectedIds.length === 0 ||
      selectedIds.length + disabledArr.length < tableBodyArr.length
    ) {
      onCheckboxSelect(
        data.body.filter((item) => !disabledArr.includes(item?.id))
      );
    } else if (
      selectedIds.length + disabledArr.length ===
      tableBodyArr.length
    ) {
      onCheckboxSelect([]);
    } else {
      onCheckboxSelect([]);
    }
  };

  const onSelectCheckbox = (value) => {
    onCheckboxSelect(
      selectedIds.includes(value?.id)
        ? data?.selected.filter((item) => item?.id !== value?.id)
        : [...data?.selected, value]
    );
  };

  return (
    <table className="table__wrapper">
      <thead className="table__thead_wrapper">
        <tr className="table__thead_row_wrapper">
          {config?.checkbox && (
            <th className="table__checkbox_wrapper">
              <Checkbox
                name="select all checkbox"
                checked={
                  (selectedIds.length === data?.body?.length &&
                    data?.body?.length > 0) ||
                  false
                }
                onChange={onSelectAll}
                additionalClassName={"table__checkbox_style"}
              />
            </th>
          )}
          {data?.header &&
            data.header.map((headerItem) => (
              <th
                key={headerItem.column || ""}
                title={headerItem.title || ""}
                style={headerItem.style || null}
                className={`table__th_wrapper ${getAlign(
                  headerItem.sort || headerItem.column
                )}`}
              >
                {headerItem.title || ""}
              </th>
            ))}
        </tr>
      </thead>
      <tbody>
        {data?.body &&
          data.body.map((bodyItem) => (
            <tr
              key={bodyItem?.[data?.keyId] || bodyItem?.id || ""}
              className={`table__body_row_wrapper ${
                data?.config?.selectable
                  ? "table__body_row_wrapper--selectable"
                  : ""
              }`}
              onClick={(e) =>
                onTableRowClick({
                  e,
                  selectedItem: bodyItem,
                })
              }
            >
              {config?.checkbox && (
                <td className="table__checkbox_wrapper">
                  <Checkbox
                    name="select all checkbox"
                    checked={selectedIds?.includes(bodyItem?.id)}
                    disabled={data?.disabled?.includes(bodyItem?.id)}
                    onChange={() => onSelectCheckbox(bodyItem)}
                    additionalClassName={"table__checkbox_style"}
                  />
                </td>
              )}
              {data?.header &&
                data.header.map((headerItem) => (
                  <td
                    key={headerItem.column || ""}
                    title={bodyItem[headerItem.column] || ""}
                    className={`table__td_wrapper ${getAlign(
                      headerItem.sort || headerItem.column
                    )}`}
                  >
                    {config?.tableType === "files" && (
                      <Link to={{ pathname: `/filesDetails/${bodyItem?.id}` }}>
                        {headerItem.column == "offlineState"
                          ? convertDownloadState(bodyItem[headerItem.column])
                          : bodyItem[headerItem.column] || ""}
                      </Link>
                    )}

                    {config?.tableType !== "files" && (
                        headerItem.column == "offlineState"
                          ? convertDownloadState(bodyItem[headerItem.column])
                          : bodyItem[headerItem.column] || ""
                    )}
                  </td>
                ))}
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default Table;
