import moment from 'moment';

export const timeParserLineChart = ({ unix, format }) => {
  if (unix && format) {
    return moment.unix(unix).format(format);
  }

  return '';
}

export const timeParserUnixToDate = ({ unix }: { unix: number }) => {
  if (unix && typeof unix === 'number') {
    return moment.unix(unix).format("DD/MM/YY, HH:mm:ss");
  }

  return '';
}
