// @flow

import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './styles.scss';
import KeyPairGenerator from '../../components/KeyPairGenerator'
import ChangePassword from '../../components/ChangePassword'
import DownloadApp from '../../components/DownloadApp'
import ChangeInfo from '../../components/ChangeInfo'


const Settings = () => {
  const [tabIndex, setTabIndex] = useState(0);


  return (
    <>

      <h2 className='settings__text'>Account</h2>

      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}
      >
        <TabList>
          {/* <Tab>Repositories</Tab> */}
          <Tab>Change Password</Tab>
          <Tab>Change Info</Tab>
          <Tab >Download App</Tab>
          <Tab >Generate Keys</Tab>
        </TabList>
        <TabPanel><ChangePassword /></TabPanel>
        <TabPanel><ChangeInfo /></TabPanel>
        <TabPanel><DownloadApp/></TabPanel>
        <TabPanel><KeyPairGenerator /></TabPanel>

      </Tabs>



    </>
  )
}







export default Settings;