// @flow

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import InputFile from "../../components/InputFile";
import Table from "../../components/Table";
import Button from "../../components/Button";
import CreateUserWizard from "../../components/CreateUserWizard";
import ChangeUserPasswordWizard from "../../components/ChangeUserPasswordWizard";
import ResetUserPasswordWizard from '../../components/ResetUserPasswordWizard'

import locale from "../../locale";

import { getUsersTableData,getWhoAmI } from "../../store/selectors";

import {
  getUsers,
  RegistrInServerNew,
  deleteUsersReq,
  changePassword,
  changeUserInfo
} from "../../store/actions";

import "./styles.scss";

const Users = () => {
  const dispatch = useDispatch(); // use this to dispatch an actions
  const usersTableData = useSelector(getUsersTableData); // use this to get data from store;
  const [showAdd, setShowAdd] = useState(false);
  const [showPassWindow, setShowPassWindow] = useState(false);
  const [showResetWindow, setShowResetWindow] = useState(false);
  const [tableSelected, setTableSelected] = useState([]);
  const whoAmI = useSelector(getWhoAmI);
  const isAdmin =  whoAmI?.role?.some((item) => item === "Admin");

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const createUser = (obj) => {
    dispatch(RegistrInServerNew(obj)).then((res) => {
      if (res === "ok") {
        setShowAdd(false);
        dispatch(getUsers());
      }
    });
  };

  const onTableCheckboxSelect = (selectedArr) => {
    setTableSelected(selectedArr);
  };

  const deleteUsers = () => {

  // eslint-disable-next-line no-restricted-globals
    let conf = confirm('Delete users?')

    if (conf) {
      dispatch(deleteUsersReq(tableSelected.map(item => item.id))).then((res) => {
        if (res === "ok") {
          dispatch(getUsers());
        }
      });
    }

    
  };


  const changeUserPassword = (obj) => {
    dispatch(changePassword(obj)).then((res) => {
      if (res === "ok") {
        setShowPassWindow(false);
        dispatch(getUsers());
      }
    });
  };

  const changeUserInfoClick = (obj) => {
    dispatch(changeUserInfo(obj)).then((res) => {
      if (res === "ok") {
        setShowPassWindow(false);
        dispatch(getUsers());
      }
    });
  }

  return isAdmin ? (
    <div className="users__wrapper">
      <div className="users__title_wrapper">
        <h1 className="users__title">Users</h1>
      </div>
      <div className="users__btn_panel_wrapper">
        <Button onClick={() => setShowAdd(true)} caption={"Create user"} />
        <Button
          onClick={() => deleteUsers()}
          disabled={tableSelected.length === 0}
          caption={"Delete user"}
        />
        <Button
          onClick={() => setShowPassWindow(true)}
          disabled={tableSelected.length !== 1}
          caption={"change info"}
        />
        <Button
          onClick={() => setShowResetWindow(true)}
          disabled={tableSelected.length !== 1}
          caption={"reset password"}
        />
      </div>
      <div className="users__table_widget_wrapper">
        <div className="users__table_wrapper">
          <div className="wt_header_wrapper">
            <div className="wt_header_wrapper_text">Users</div>
          </div>
          <div className="table-wrap-with-background">
            <Table
              data={{
                ...(usersTableData || null),
                selected: tableSelected,
              }}
              config={{ checkbox: true, tableType:'users' }}
              onCheckboxSelect={onTableCheckboxSelect}
            />
          </div>
        </div>
      </div>
      {showAdd && (
        <CreateUserWizard
          onConfirm={(obj) => createUser(obj)}
          onClose={() => setShowAdd(false)}
        />
      )}
      {showPassWindow && (
        <ChangeUserPasswordWizard
          user={tableSelected[0]}
          onConfirm={(obj) => changeUserPassword(obj)}
          onConfirmInfo={(obj) => changeUserInfoClick(obj)}
          onClose={() => setShowPassWindow(false)}
        />
        
      )}
      {showResetWindow && (
        <ResetUserPasswordWizard
          user={tableSelected[0]}
          onClose={() => setShowResetWindow(false)}
        />
      )}
    </div>
  ) : <div>Page not found</div>;
};

export default Users;
