// flow

import React, { useState } from "react";
import ModalWindow from "../ModalWindow";
import { useDispatch } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import Input from "../Input";
import ButtonWizard from "../ButtonWizard";
import Checkbox from "../Checkbox";
import { ShowAlert } from '../Alert/AlertAction';
import locale from "../../locale";

import "./styles.scss";

type Props = {
  onConfirm: (data: string) => void,
  onClose: () => void,
};

const ChangeUserPasswordWizard = ({ onConfirm, onClose, user,onConfirmInfo }) => {
  const [password, setPassword] = useState("");
  const [temp, setTemp] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [userValue, setUserValue] = useState(user?.fullData?.userName || '');
  const [emailValue, setEmailInputValue] = useState(user?.fullData?.email || '');

  const dispatch = useDispatch();

  const onPassInputChange = (e) => {
    setPassword(e.target.value);
  };

  const onConfirmClick = () => {


    if (!password) {
      dispatch(ShowAlert('warning', 'Please fill password', false, false));
      return;
    }


    if (password) {
      let obj = {
        login: user.id,
        newPassword: password,
        isPasswordTemporary: temp
      };

      onConfirm(obj);
    }
  };

  const onConfirmClickInfo = () => {

    if (!userValue || !emailValue) {
      dispatch(ShowAlert('warning', 'Please fill fields', false, false));
      return;
    }

    let obj = {
      ...user.fullData,
      userName:userValue,
      email:emailValue
    };
    onConfirmInfo(obj)
  }


  const onUserInputChange = (e) => {
    setUserValue(e.target.value);
  }



  const onEmailInputChange = (e) => {
    setEmailInputValue(e.target.value);
  }



  return (
    <ModalWindow title={"Change user info"} onBtnCloseClick={onClose}>
      <div className="change_password__wrapper">
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}
        >
          <TabList>
            <Tab>Change Password</Tab>
            <Tab>Change Info</Tab>
          </TabList>
          <TabPanel>
            <div className="userName">{user?.userName}</div>
            <p className="change_password__label">new Password</p>
            <Input
              value={password}
              type="password"
              showPassBtn
              onChange={onPassInputChange}
            />
            <div className="checkbox-temp-wrapper">
              <label className='temp__checkbox_label_wrapper'>
                <Checkbox
                  name='remember credentials checkbox'
                  id='remember_cred'
                  checked={temp}
                  onChange={() => setTemp(!temp)}
                  additionalClassName={'login__checkbox_style'}
                />
                <p className='temp__checkbox_label_text'>Set password as temporary</p>
              </label>
            </div>
            <div className="change_password__btn_wrapper">
              <div className="change_password__btn">
                <ButtonWizard
                  caption={locale("IDS_COMMON_BTN_CAPTION_CLOSE")}
                  onClick={onClose}
                />
              </div>
              <div className="change_password__btn">
                <ButtonWizard caption={"Change"} active onClick={onConfirmClick} />
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <p className='change_password__label'>E-mail</p>
            <div className='login__input_wrapper'>
              <Input
                name='email input'
                type='text'
                placeholder={'example@domain.com'}
                value={emailValue}
                warning={''}
                onChange={onEmailInputChange}
              />
            </div>
            <p className='change_password__label'>User name</p>
            <div className='login__input_wrapper'>
              <Input
                name='username input'
                type='text'
                value={userValue}
                warning={''}
                onChange={onUserInputChange}
              />
            </div>
            <div className="change_password__btn_wrapper">
              <div className="change_password__btn">
                <ButtonWizard
                  caption={locale("IDS_COMMON_BTN_CAPTION_CLOSE")}
                  onClick={onClose}
                />
              </div>
              <div className="change_password__btn">
                <ButtonWizard caption={"Change"} active onClick={onConfirmClickInfo} />
              </div>
            </div>
          </TabPanel>
        </Tabs>

      </div>
    </ModalWindow>
  );
};

export default ChangeUserPasswordWizard;
