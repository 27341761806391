import { Axiosinstance } from '../../api';
import * as api from '../../api';
import * as types from './actionTypes';
import catchRequestError from '../../commonExtensions/catchRequestError';

export const setUsers = payload => ({
  type: types.SET_USERS,
  payload
});

export function getUsers() {
  return (dispatch) => {
    Axiosinstance.get(api.users)
      .then((response) => {
        if (response?.data) {
          dispatch(setUsers(response.data));
        }
      })
      .catch((error) => {
        catchRequestError(error, dispatch);
      })
  }
};

export function deleteUsersReq(param) {
  return (dispatch) => {
    return (
    Axiosinstance.delete(api.deleteUsers,{ data: { userLogins: param }})
      .then((response) => {
        if (response?.data) {
          return 'ok'
        }
      })
      .catch((error) => {
        catchRequestError(error, dispatch);
      })
    )
  }
};



