// @flow

import React, { useState, useEffect } from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';

import TotalWidget from '../../components/TotalWidget';
import AreaChartWidget from '../../components/AreaChartWidget';
import Table from '../../components/Table';
import SelectBox from '../../components/SelectBox';

import {
  getTotalFilesCount,
  getUsedFilesCapacity,
  getWidgetData_bandwidth,
  getWidgetData_storage,
  getDashboardChartPeriodValue,
  getTotalPeriodBandwidth,
  getPaymentInfo,
} from '../../store/selectors';
import {
  getFiles,
  getWidgetData,
  setDashboardChartPeriod,
} from '../../store/actions';

import { linechartPeriodOpt } from '../../components/LineChartWidget/lineChartExtensions';

import locale from '../../locale';

import './styles.scss';

let getWidgetDataIntervalRequest = null;

const Dashboard = () => {
  const dispatch = useDispatch();
  const totalFilesCount = useSelector(getTotalFilesCount);
  const usedFilesCapacity = useSelector(getUsedFilesCapacity);
  const chartPeriodValue = useSelector(getDashboardChartPeriodValue);
  const totalPeriodBandwidth = useSelector(getTotalPeriodBandwidth);
  const paymentInfo = useSelector(getPaymentInfo);

  const widget_bandwidth_data = useSelector(getWidgetData_bandwidth); // add this to widget , map it before use in selector
  const widget_storage_data = useSelector(getWidgetData_storage); // add this to widget, map it before use in selector

  const [selectedChartPeriod, setSelectedChartPeriod] = useState(linechartPeriodOpt.find(item => item.value === chartPeriodValue));

  const getDataForCharts = (value) => {
    dispatch(getWidgetData(value));
    getWidgetDataIntervalRequest = setInterval(() => dispatch(getWidgetData(value)), 15000);
  }

  useEffect(() => {

    dispatch(getFiles());
    getDataForCharts(selectedChartPeriod.value);

    let getFilesIntervalRequest = setInterval(() => dispatch(getFiles()), 30000);

    return () => {
      clearInterval(getFilesIntervalRequest);
      clearInterval(getWidgetDataIntervalRequest);
    } 
  }, []);

  const selectChartPeriod = (obj) => {
    setSelectedChartPeriod(obj);
    dispatch(setDashboardChartPeriod(obj.value));
    clearInterval(getWidgetDataIntervalRequest);
    getDataForCharts(obj.value)
  }

  return (
    <div className='dashboard__wrapper'>
      <h1 className='dashboard__title'>{locale('IDS_DASHBOARD_TITLE')}</h1>
      <div className='dashboard__widgets_separator'>
        
        <div className='dashboard__totalwidget_wrapper'>
          <TotalWidget
            data={{
              title: locale('IDS_DASHBOARD_TOTAL_WIDGET_FILES_TITLE'),
              value: totalFilesCount || '0',
            }}
          />
        </div>
        <div className='dashboard__totalwidget_wrapper'>
          <TotalWidget
            data={{
              title: locale('IDS_DASHBOARD_TOTAL_WIDGET_USEDCAPACITY_TITLE'),
              value: usedFilesCapacity,
            }}
          />
        </div>
        <div className='dashboard__totalwidget_wrapper'>
          <TotalWidget
           data={{
            title: locale('IDS_DASHBOARD_TOTAL_WIDGET_MONTHLY_PAYMENT_TITLE'),
            value: paymentInfo || '$ 0',
          }}
           
           />
        </div>
        {/* <div className='dashboard__totalwidget_wrapper'>
          <TotalWidget data={totalBandwidthData} />
        </div> */}
      </div>
      <div className='dashboard__widgets_separator'>
        <div className='dashboard__widgets_graph_wrapper'>
          <div className="wt_header_wrapper dashboard__widgets_graph_header">
            <p>
              {locale('IDS_DASHBOARD_LINECHART_GRAPHS_TITLE')}
            </p>
            <div className='dashboard__widgets_graph_select'>
              <SelectBox
                type='linechart'
                placeholder = ''
                clearable={false}
                deleteRemoves={false}
                backspaceRemoves={false}
                searchable={false}
                disabled={false}
                options={linechartPeriodOpt}
                selected={selectedChartPeriod}
                onSelectOpt={selectChartPeriod}
              />
            </div>
          </div>
          <div className='dashboard__linechart_content_wrapper'>
            <div className='dashboard__linechart_widget_wrapper'>
              <div className='dashboard__linechart_widget_container'>
                <h2 className='dashboard__linechart_widget_title'>{locale('IDS_DASHBOARD_LINECHART_WIDGET_STORAGE_TITLE')}</h2>
                <div className='dashboard__linechart_wrapper'>
                  <AreaChartWidget
                    data={widget_storage_data}
                  />
                </div>
              </div>
            </div>
            <div className='dashboard__linechart_widget_wrapper'>
              <div className='dashboard__linechart_widget_container'>
                <div className='dashboard__linechart_widget_title_wrapper'>
                  <h2 className='dashboard__linechart_widget_title'>{locale('IDS_DASHBOARD_LINECHART_WIDGET_BANDWIDTH_TITLE')}</h2>
                  <p className='dashboard__linechart_widget_value'>{locale('IDS_DASHBOARD_LINECHART_WIDGET_TOTALPERIOD_TITLE')} <span>{totalPeriodBandwidth || ''}</span></p>
                </div>
                <div className='dashboard__linechart_wrapper'>
                  <AreaChartWidget
                    data={widget_bandwidth_data}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='dashboard__table_widget_wrapper'>
        <div className='dashboard__table_wrapper'>
          <div className="wt_header_wrapper">
            <div className="wt_header_wrapper_text">
            Last events
            </div>
            </div>
            <div className="table-wrap-with-background">
          <Table data={tableData} />
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default Dashboard;
