// @flow

import React, { useState } from 'react';
import Input from '../../components/Input';
import './styles.scss';
import Button from '../Button'
import { getWhoAmI } from "../../store/selectors";


import {
  useSelector,
  useDispatch,
} from 'react-redux';

import {
  changeUserInfo 
} from '../../store/actions';



const ChangeInfo = () => {

  const dispatch = useDispatch();
  const whoAmI = useSelector(getWhoAmI);
  const [userValue, setUserValue] = useState(whoAmI?.userName);
  const [emailValue, setEmailInputValue] = useState(whoAmI?.email);
 


  const changePass = () => {

    let obj = {
      ...whoAmI,
      userName:userValue,
      email:emailValue
    }

    if (!userValue || !emailValue) {
      alert ('fill all fields');
      return;
    }
    
    dispatch(changeUserInfo(obj));
  }

  const onUserInputChange = (e) => {
    setUserValue(e.target.value);
  }

 

  const onEmailInputChange = (e) => {
    setEmailInputValue(e.target.value);
  }

  return (
    <div className='changePassword__container_wrapper'>
      <div className='login'>Login: {whoAmI?.login}</div>
      <div>Change Info</div>
      <div className="content_wrapper">
      <p className='login__input_title'>E-mail</p>
        <div className='login__input_wrapper'>
          <Input
            name='email input'
            type='text'
            placeholder={'example@domain.com'}
            value={emailValue}
            warning={''}
            onChange={onEmailInputChange}
          />
        </div>
        <p className='login__input_title'>User name</p>
        <div className='login__input_wrapper'>
          <Input
            name='username input'
            type='text'
            value={userValue}
            showPassBtn
            warning={''}
            onChange={onUserInputChange}
          />
        </div>
        {/* <p className='login__input_title'>New Password</p>
        <div className='login__input_wrapper'>
          <Input
            name='password input'
            type='password'
            value={passReInputValue}
            showPassBtn
            warning={''}
            onChange={onPassReInputChange}
          />
        </div> */}
       <Button onClick={changePass} caption="Change info" />
       
      </div>
    </div>
  )
};

export default ChangeInfo;