// @flow

import React, { useState } from 'react';
import Input from '../../components/Input';
import './styles.scss';
import Button from '../Button'
import { getWhoAmI } from "../../store/selectors";


import {
  useSelector,
  useDispatch,
} from 'react-redux';

import {
  changePassword as ChPass
} from '../../store/actions';



const ChangePassword = () => {

  const dispatch = useDispatch();
  const [passInputValue, setPassInputValue] = useState('');
  const [passReInputValue, setPassReInputValue] = useState('');
  const { login = '' } = useSelector(getWhoAmI);
  const changePass = () => {

    let obj = {
      oldPassword:passInputValue,
      newPassword:passReInputValue
    }

    if (!passInputValue || !passReInputValue) {
      alert ('fill all fields');
      return;
    }
    dispatch(ChPass(obj));
  }
  const onPassInputChange = (e) => {
    setPassInputValue(e.target.value);
  }
  const onPassReInputChange = (e) => {
    setPassReInputValue(e.target.value);
  }

  return (
    <div className='changePassword__container_wrapper'>
      <div className='login'>Login: {login}</div>
      <div>Change Password</div>
      <div className="content_wrapper">
  
        <p className='login__input_title'>Old Password</p>
        <div className='login__input_wrapper'>
          <Input
            name='password input'
            type='password'
            value={passInputValue}
            showPassBtn
            warning={''}
            onChange={onPassInputChange}
          />
        </div>
        <p className='login__input_title'>New Password</p>
        <div className='login__input_wrapper'>
          <Input
            name='password input'
            type='password'
            value={passReInputValue}
            showPassBtn
            warning={''}
            onChange={onPassReInputChange}
          />
        </div>
       <Button onClick={changePass} caption="Change password" />
       
      </div>
    </div>
  )
};

export default ChangePassword;