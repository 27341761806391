import * as types from '../actions/actionTypes';

const initialState = {
  isLogin: false,
}

const app = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_LOGIN_STATUS:
      return {...state, isLogin: action.payload};
    default:
      return state;
  }
}

export default app;