//flow

import React from 'react';

import './styles.scss';

type Props = {
  data: {
    type: string,
    counter: number,
  },
  onClick: () => void,
}

const getIcon = (type) => {
  if (type === 'events') return 'header_btn__wrapper--events';
  if (type === 'notifications') return 'header_btn__wrapper--notifications';

  return '';
}

const HeaderBtn = ({
  data,
  onClick,
}: Props) => (
  <button
    className={`header_btn__wrapper ${getIcon(data?.type)}`}
    onClick={onClick}
  >
    {typeof data?.counter === 'number' &&
      data.counter > 0 &&
      <div className='header_btn__counter_wrapper'>
        <p className='header_btn__counter_value'>{data.counter}</p>
      </div>}
  </button>
);

export default HeaderBtn;
