import byteSize from 'byte-size';
import locale from '../locale';

const ISUCheck = (): boolean => {
  let useISU = localStorage.getItem('useISU');

  return useISU === 'true';
}

export const localeMB = (): string => {
  return ISUCheck() ? locale('IDS_COMMON_METRIC_MB') : locale('IDS_COMMON_METRIC_MIB');
};
export const localeGB = (): string => {
  return ISUCheck() ? locale('IDS_COMMON_METRIC_GB') : locale('IDS_COMMON_METRIC_GIB');
}

export const MB = (): number => {
  return ISUCheck() ? 1000000 : 1048576;
};

export const GB = (): number => {
  return ISUCheck() ? 1000000000 : 1073741824;
};

export const TB = (): number => {
  return ISUCheck() ? 1000000000000 : 1099511627776;
};

export const getParsedValueObj = (value) => {
  let parsedValue = null;

  if (ISUCheck()) {
    parsedValue = byteSize(value, { precision: value >= TB() ? 2 : 1 });
  } else {
    parsedValue = byteSize(value, { units: 'iec', precision: value >= TB() ? 2 : 1 });
  }
  
  return {
    ...parsedValue,
    value: parsedValue?.value ? +(parseFloat(parsedValue.value.replace(/,/g, '.'))).toFixed(2) : '',
  };
}

const getParsedNumber = (num) => {
  return typeof num === 'number' ? num : '';
}

const getParsedMetric = (str) => {
  return typeof str === 'string' ? str : '';
}

export const convertBytes = (value: number): string => {
  let parsedValue = typeof value === 'number' ? getParsedValueObj(value) : null;

  return parsedValue ? `${getParsedNumber(parsedValue.value)} ${getParsedMetric(parsedValue.unit)}` : '';
}

export const getConvertedBytesObj = ({ value, additionalString }) => {
  let parsedValue = typeof value === 'number' ? getParsedValueObj(value) : null;

  return parsedValue ? {
    number: getParsedNumber(parsedValue.value),
    string: `${getParsedMetric(parsedValue.unit)}${additionalString || ''}`,
  } : null;
}