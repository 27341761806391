//flow

import React from 'react';

import './styles.scss';



const NodeCountWidget = ({ data
}) => (
  <div className='nodeCountWidget_wrapper'>
    <div className="line-1-header">
      <div className="left-b-header">Storage nodes</div>
      <div className="left-r-header">{data.nodeCounts}</div>
    </div>
    <div className={data.onlineNodes > 0 ? 'line-1' : 'line-1 grey-text'}>
      <div className="left-b">
        <span className={data.onlineNodes > 0 ? 'green-circle' : 'grey-cricle'}></span>
        Online</div>
      <div className="left-r">{data.onlineNodes}</div>
    </div>
    <div className={data.offlineNodes > 0 ? 'line-1' : 'line-1 grey-text'}>
      <div className="left-b">
      <span className={data.offlineNodes  > 0 ? 'red-circle' : 'grey-cricle'}></span>
        Offline</div>
      <div className="left-r">{data.offlineNodes}</div>
    </div>
    <div className={data.replacement > 0 ? 'line-1' : 'line-1 grey-text'}>
      <div className="left-b">
      <span className={data.replacement  > 0 ? 'yellow-circle' : 'grey-cricle'}></span>
        Replacement
        </div>
      <div className="left-r">{data.replacement}</div>
    </div>
  </div>
);

export default NodeCountWidget;
