//flow

import React from 'react';

import './styles.scss';

type Props = {
  data: {
    title: string,
    value: string | number,
  }
};

const TotalWidget = ({
  data,
}: Props) => (
  <div className='total_widget__wrapper'>
    <div title={data?.title || ''} className='total_widget__title'>{data?.title || ''}</div>
    <div title={data?.value || ''} className='total_widget__value'>{data?.value || ''}</div>
  </div>
);

export default TotalWidget;
