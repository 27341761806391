// @flow

import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import locale from "../../locale";

import { getWhoAmI } from "../../store/selectors";

import "./styles.scss";

const Sidebar = ({ routes }) => {
  const { pathname } = useLocation();
  const [activeLink, setActiveLink] = useState(pathname);
  const whoAmI = useSelector(getWhoAmI);
  const isAdmin =  whoAmI?.role?.some((item) => item === "Admin");
  console.log(routes);
  console.log(isAdmin);

  routes = routes.map((item) => {
    if (item.path === "/users") {
      return { ...item, sidebar: isAdmin };
    } else {
      return item;
    }
  });

  useEffect(() => {
    if (pathname === "/") {
      setActiveLink("/dashboard");
    }

    if (pathname !== activeLink) {
      setActiveLink(pathname);
    }
  }, [pathname]);

  const getSidebarItemLocale = (path) => {
    switch (path) {
      case "/dashboard":
        return locale("IDS_SIDEBAR_DASHBOARD_LINK");
      case "/containers":
        return locale("IDS_SIDEBAR_CONTAINERS_LINK");
      case "/files":
        return locale("IDS_SIDEBAR_FILES_LINK");
      case "/billing":
        return locale("IDS_SIDEBAR_BILLING_LINK");
      case "/account":
        return locale("IDS_SIDEBAR_ACCOUNT_LINK");
      case "/users":
        return locale("IDS_SIDEBAR_USERS_LINK");
      default:
        return "";
    }
  };

  const getIcon = (path) => {
    switch (path) {
      case "/dashboard":
        return "sidebar__item_link--dashboard";
      case "/containers":
        return "sidebar__item_link--containers";
      case "/files":
        return "sidebar__item_link--files";
      case "/billing":
        return "sidebar__item_link--billing";
      case "/account":
        return "sidebar__item_link--settings";
      case "/users":
        return "sidebar__item_link--users";
      default:
        return "";
    }
  };

  const getSidebarList = (routeItem) => {
    if (routeItem.path !== "/") {
      return (
        <Link
          key={routeItem.path}
          to={routeItem.path}
          className={`sidebar__item_link ${
            activeLink.includes(routeItem.path) ? "active" : ""
          } ${getIcon(routeItem.path)}`}
          onClick={(e) => onLinkClick(e, routeItem.path)}
        >
          {getSidebarItemLocale(routeItem.path)}
        </Link>
      );
    }

    return null;
  };

  const onLinkClick = (e, linkName) => {
    if (activeLink === linkName) {
      e.preventDefault();
    }

    setActiveLink(linkName);
  };

  return (
    <div className="sidebar__wrapper">
      <h1 className="sidebar__title">
        {locale("IDS_LOGO_STARWIND_TITLE")}{" "}
        <span>{locale("IDS_LOGO_DS_TITLE")}</span>
      </h1>
      <nav className="sidebar__nav_wrapper">
        {routes?.filter((item) => item.sidebar).map(getSidebarList)}
      </nav>
    </div>
  );
};

export default Sidebar;
