// @flow

import React from 'react';

import './styles.scss';

type Props = {
  name: String,
  type: String,
  caption: String,
  disabled: Boolean,
  onClick: Function,
};

const Button = ({
  name = 'button',
  type = 'button',
  caption = '',
  disabled = false,
  onClick = () => {},
}: Props) => (
  <button
    type={type}
    name={name}
    className={`button__wrapper ${disabled ? 'button__disabled_wrapper' : ''}`}
    onClick={(e) => !disabled && onClick(e)}
  >
    {caption}
  </button>
);

export default Button;