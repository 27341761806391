// flow

import React, { useState } from 'react';
import ModalWindow from '../ModalWindow';
import Input from '../Input';
import ButtonWizard from '../ButtonWizard';

import locale from '../../locale';

import './styles.scss';

type Props = {
  onConfirm: (data: string) => void,
  onClose: () => void,
};

const DownloadFileWizard = ({
  onConfirm,
  onClose,
}: Props) => {
  const [path, setPath] = useState('');

  const onPathInputChange = (e) => {
    setPath(e.target.value);
  }

  const onConfirmClick = () => {
    onConfirm(path);
  }

  return (
    <ModalWindow
      title={locale('IDS_FILES_DOWNLOADFILE_WIZARD_TITLE')}
      onBtnCloseClick={onClose}
    >
      <div className='download_file__wrapper'>
        <p className='download_file__label'>
          {locale('IDS_FILES_DOWNLOADFILE_WIZARD_PATH_LABEL')}
        </p>
        <Input
          value={path}
          onChange={onPathInputChange}
        />
        <div className='download_file__btn_wrapper'>
          <div className='download_file__btn'>
            <ButtonWizard
              caption={locale('IDS_COMMON_BTN_CAPTION_CLOSE')}
              onClick={onClose}
            />
          </div>
          <div className='download_file__btn'>
            <ButtonWizard
              caption={locale('IDS_COMMON_BTN_CAPTION_DOWNLOAD')}
              active
              onClick={onConfirmClick}
            />
          </div>
        </div>
      </div>
    </ModalWindow>
  )
}

export default DownloadFileWizard;
