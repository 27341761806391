// @flow

import React, { useEffect, useState } from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import InputFile from '../../components/InputFile';
import Table from '../../components/Table';
import Button from "../../components/Button";

import locale from '../../locale';

import {
  getFilesTableData,
} from '../../store/selectors';

import {
  getFiles,
  postFiles,
  deleteFilesReq
} from '../../store/actions';

import './styles.scss';

const Files = () => {
  const dispatch = useDispatch(); // use this to dispatch an actions
  const filesTableData = useSelector(getFilesTableData); // use this to get data from store;
  const [tableSelected, setTableSelected] = useState([]);

  // if (filesTableData?.body) {
  //   filesTableData.body.push({'id':'fghjk',name:'',size:'',lastModified:''})
  // }
  
  

// console.log(filesTableData.body[0].id)

  const uploadBtn = {
    type: 'upload',
    caption: locale('IDS_FILES_UPLOADFILE_BTN_CAPTION'),
  };

  const onUploadFile = (e) => {

    let file = e.target.files[0]
    let fd = new FormData();
    let obj = {
        "filePath": file.name,
        "desiredBackupCount": 2
    
    }
    
   dispatch(postFiles(obj));
  }

  const onTableCheckboxSelect = (selectedArr) => {
    setTableSelected(selectedArr);
  };

  const deleteFiles = () => {
   // eslint-disable-next-line no-restricted-globals
    let conf = confirm('Delete files?')
    if (conf) {
      
      dispatch(deleteFilesReq(tableSelected.map(item => item.id))).then((res) => {
        if (res === "ok") {
          dispatch(getFiles());
        }
      });
   }

  }

   console.log(tableSelected)

  return (
    <div className='files__wrapper'>
      <div className='files__title_wrapper'>
        <h1 className='files__title'>{locale('IDS_FILES_TITLE')}</h1>
      </div>
        {/* <div className='files__btn_panel_wrapper'>
          <InputFile
            data={uploadBtn}
            onChange={onUploadFile}
          />
        </div> */}
        <div className='btn-delete-wrapper'>
        <Button
          onClick={() => deleteFiles()}
          disabled={tableSelected.length === 0}
          caption={"Delete files"}
        />
        </div>
         
        <div className='files__table_widget_wrapper'>
          <div className='files__table_wrapper'>
          <div className="wt_header_wrapper">
            <div className="wt_header_wrapper_text">
            List of uploaded files
            </div>
          
            </div>
           
            <div className="table-wrap-with-background">
            <Table  
            onCheckboxSelect={onTableCheckboxSelect}  
            config={{ checkbox: true, tableType:'files' }}
            data={{
              ...(filesTableData || null),
              selected: tableSelected,
            }}/>
            </div>
            
          </div>
        </div>
    </div>
  )
}

export default Files;
