//flow

import React from 'react';

import locale from '../../locale';

import './styles.scss';

type Props = {
  data: {
    status: string,
    distribution: string,
    details: string,
    availability: string,
  }
};

const getStatusIcon = (state) => {
  switch (state) {
    case 'ok':
      return 'file_fragments__content_status--ok';
    case 'warning':
      return 'file_fragments__content_status--warning';
    case 'created':
      return 'file_fragments__content_status--repairing';
    case 'scaling':
      return 'file_fragments__content_status--repairing';
    case 'downloading':
      return 'file_fragments__content_status--repairing';
    case 'degraded':
      return 'file_fragments__content_status--repairing';
    case 'repairing':
      return 'file_fragments__content_status--repairing';
    case 'uploading':
      return 'file_fragments__content_status--repairing';
    case 'unavailable':
      return 'file_fragments__content_status--down';

    default:
      return 'file_fragments__content_status--down';
  }
}

const FileFragmentsWidget = ({
  data,
}: Props) => (
  <div className='file_fragments__wrapper'>
    <h2 className='file_fragments__title'>{locale('IDS_FILES_FRAGMENTS_WIDGET_TITLE')}</h2>
    <div className='file_fragments__main_wrapper'>
      <div className='file_fragments__content_wrapper'>
        <div className='file_fragments__content_block'>
          <p className='file_fragments__content_title'>{locale('IDS_FILES_FRAGMENTS_WIDGET_CURRENTSTATUS_TITLE')}</p>
          <p className={`file_fragments__content_value file_fragments__content_status ${getStatusIcon(data?.state)}`}>{data?.stateString || ''}</p>
        </div>
        <div className='file_fragments__content_block'>
          <p className='file_fragments__content_title'>{locale('IDS_FILES_FRAGMENTS_WIDGET_DISTRIBUTION_TITLE')}</p>
          <p className='file_fragments__content_value'>{data?.distribution || ''}</p>
        </div>
      </div>
      <div className='file_fragments__content_wrapper'>
        <div className='file_fragments__content_block'>
          <p className='file_fragments__content_title'>{locale('IDS_FILES_FRAGMENTS_WIDGET_DETAILS_TITLE')}</p>
          <p className='file_fragments__content_value'>{data?.details || ''}</p>
        </div>
        <div className='file_fragments__content_block'>
          <p className='file_fragments__content_title'>{locale('IDS_FILES_FRAGMENTS_WIDGET_AVAILABILITY_TITLE')}</p>
          <p className='file_fragments__content_value'>{data?.availability || ''}</p>
        </div>
      </div>
    </div>
  </div>
);

export default FileFragmentsWidget;