//flow
import React, { useState, useEffect } from 'react';

import './styles.scss';
import {
  useSelector,
  useDispatch,
} from 'react-redux';

import {getNewToken} from '../../store/actions'


function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};


const SwitcherToSellerUi = ({
  logout
}: Props) => {
  const dispatch = useDispatch();
  // const filesPopupWidgetData = useSelector(getFilesPopupWidgetData);

  const [showLink, setShowLink] = useState(false);

  const [link, setLink] = useState('');

  useEffect(() => {

    const parsedToken = parseJwt(localStorage.getItem('token'));

    let { client_id } = parsedToken;

    if (client_id) {
      setShowLink(true);
    }
  }, []);


  const switchUi = () => {
    dispatch(getNewToken()).then((response) => {

      let array = window.location.host.split('.');
      if (array.length > 1) {
        array.shift();
      }
      array = array.join('.')
       // let href ='http://localhost:3003/?token='+ response + '&userName=' + localStorage.getItem('userName')
      let href = ''
       href = window.location.protocol + '//seller-ui.' + array + '/?token=' + response + '&userName=' + localStorage.getItem('userName');

      window.location.href = href;
    })
  }


  if (showLink) {
    return (
      <a onClick={switchUi} className='switcher-link'>
        Switch to Seller UI
      </a>
    )
  }
  else {
    return <></>
  }


}

export default SwitcherToSellerUi;
