import {ShowAlert} from '../components/Alert/AlertAction'

const catchUnknownError = (error, dispatch) => {
  let errorInfo = new Error(error);

  if (errorInfo) {
    // do something here
    alert(errorInfo)
  }
}


const catchRequestError = (data, dispatch) => {

 
 
  if (data?.response) {
    if (data.response?.status === 401) {
    //  alert('')
      dispatch(ShowAlert('warning', 'wrong creds', false, false));
    } else {
      
      dispatch(ShowAlert('warning', data?.response?.data, false, false));
    }
  } else {
    
    dispatch(ShowAlert('warning', data?.response?.data, false, false));
   // catchUnknownError(data, dispatch);
    
  }
}

export default catchRequestError;