// @flow

import React, { useState,useEffect } from 'react';
import './styles.scss';
import Button from '../Button'

import {
  useSelector,
  useDispatch,
} from 'react-redux';


import {
  get_link,
} from '../../store/actions';

const DownloadApp = () => {

  const dispatch = useDispatch(); 
  const [link, setLink] = useState('#');
  
  useEffect(() => {
    dispatch(get_link()).then((data)=>{
      if (data?.link) {
        setLink(data?.link)
        console.log('DOWNLOADED')
      }
    });
  }, []);
 
  return (
    <div className='downloadApp__container_wrapper'>
      <div>Download App</div>
      <div className="content_wrapper">
        
       <a target="_blank" href={link}>Download App</a>
       
      </div>
    </div>
  )
};

export default DownloadApp;