import * as types from './actionTypes';
import axios from 'axios';
import { Axiosinstance } from '../../api';
import * as api from '../../api';
import catchRequestError from '../../commonExtensions/catchRequestError';

const increaseValue = payload => ({
  type: types.INCREASE,
  payload
});

const decreaseValue = payload => ({
  type: types.DECREASE,
  payload
});

export const changeValueByThunk = (type) => (dispatch) => {
  // console.log('this is start of middleware');
  if (type === 'increase') {
    // console.log('prev state ', value)
    dispatch(increaseValue());
    // console.log('middleware in action!')
  } else if (type === 'decrease') {
    // console.log('prev state ', value)
    dispatch(decreaseValue());
    // console.log('middleware in action!')
  }
  // console.log('this is end of middleware')
}

export const setDashboardChartPeriod = payload => ({
    type: types.SET_DASHBOARD_CHART_PERIOD,
    payload
  });

export const setWidgetInfo = payload => ({
  type: types.SET_WIDGET_INFO,
  payload
});

export function getWidgetData(param) {

	return dispatch => {
		return (
			//dispatch(showLoading()),
			Axiosinstance.get(api.ui + `/widgets?period=${param}&data=true`).then(function (response) {

				dispatch(setWidgetInfo(response.data));
        
			})
				.catch((error) => {
          catchRequestError(error, dispatch);
				})
		)
	}
}
