import * as types from '../actions/actionTypes';

const initialState = {
  value: 0,
  dashItems: [
    {
      id: 1,
      name: 'item-1',
    },
    {
      id: 2,
      name: 'item-2',
    },
    {
      id: 3,
      name: 'item-3',
    },
  ],
  widget_info:[],
  chartPeriod: 'live',
}

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case types.INCREASE:
      return {...state, value: state.value + 1};
    case types.DECREASE:
      return {...state, value: state.value - 1};
    case types.SET_WIDGET_INFO:
      return {...state, widget_info: action.payload};
    case types.SET_DASHBOARD_CHART_PERIOD:
      return {...state, chartPeriod: action.payload};
    default:
      return state;
  }
}

export default dashboard;