import React, { useState, useEffect } from 'react';
import {
  useSelector,
  useDispatch,
  connect,
} from 'react-redux';
import ReactDOM from "react-dom";

import locale from '../../locale';
import ReactTooltip from "react-tooltip";
import WorldMap from "../WorldMap";
import NodeCountWidget from "../NodeCountWidget"
import  {stopnodeAction} from '../../store/actions'

import './styles.scss';



const FilePlacementWidget = ({nodeStatus,arrWithDots}) => {

  const [content, setContent] = useState("");
  const dispatch = useDispatch();

  const [contentSecond, setSecondTooltipContent] = useState({nodeId:'',x:'',y:''});

  

  const countryCodes = [
    {id:'804',name:'Ukraine'},
    {id:'840',name:'USA'},
    {id:'076',name:'Brasil'}
  ]


  const stopnode = (nodeId) => {
    dispatch(stopnodeAction(nodeId))
  }

  const tooltipView = (id) => {

    let nodeCount = arrWithDots.filter(item=> item.id == id)
    let countryName = countryCodes.find(item => item.id == id)

    return <div>
      <div><span className="tooltip-pad-1">Nodes:</span> {nodeCount.length}</div>
      <div><span className="tooltip-pad-1">Location:</span> <span className={'countryFlag countryFlag'+id}></span>  {countryName?.name}</div>
     
    </div>

  }

 
  return (
    <div  className='file_placement__wrapper'>
      
      <div className="wt_header_wrapper">
            <div className="wt_header_wrapper_text">
            {locale('IDS_FILES_PLACEMENT_WIDGET_TITLE')}
            </div>
            </div>
      <div data-tip="" 
       onClick={(e) => {
        
        if (contentSecond?.nodeId) {
          setTimeout(()=> setSecondTooltipContent({ nodeId: '', x: '', y: '' }),100)
        } 
        
    }}  className='file_placement__main_wrapper'>
        <NodeCountWidget data={nodeStatus}/>
    
        <WorldMap arrWithDots={arrWithDots} setSecondTooltip={setSecondTooltipContent} setTooltipContent={setContent} />
         
         <ReactTooltip arrowColor="transparent" className="tooltip-style">
           {content && !contentSecond?.nodeId ? <div className="">{tooltipView(content)}</div> : null}
        </ReactTooltip>


        {/* <ReactTooltip clickable={true} arrowColor="transparent" className="tooltip-style">
           {contentSecond?.id ? <div className="">Stop</div> : null}
        </ReactTooltip> */}

        {contentSecond?.nodeId ? <div style={{left:contentSecond.x, top:contentSecond.y}}  className="red-border">
        <a onClick={() => stopnode(contentSecond?.nodeId)}>Stop</a>
        </div> : null}

      </div>
    </div>
  )
}

export default FilePlacementWidget;
