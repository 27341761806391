import * as types from './actionTypes';

export const setLoginStatus = payload => ({
  type: types.SET_LOGIN_STATUS,
  payload
});

export const onDestroySession = () => {
  return dispatch => {
    dispatch({ type: types.DESTROY_SESSION });
  };
}
