// @flow

import React, { useEffect, useState } from 'react';
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from 'recharts';
import isEqual from 'lodash.isequal';
import sortBy from 'lodash.sortby';
import Loader from '../Loader';

import { timeParserLineChart } from '../../commonExtensions/timeParser';
import { getTickYFormatedData } from '../LineChartWidget/lineChartExtensions';

import {
  Id,
  Pending,
  LineChartModifiedData,
  LineChartTickValueType,
} from '../../types';

import locale from '../../locale';

import './styles.scss';

type Props = {
  data: LineChartModifiedData,
  selectedId: Id,
  firstLoadFetching: Pending,
}
const getTooltipData = ({ selectedId, data, tableData }) => {
  if (data.payload && data.payload.length > 0) {
    let time = null;

    const getTotalDataToRender = () => {
      if (data?.payload?.[0]?.payload?.tooltip && data.payload[0].payload.tooltip.length > 0) {
        return sortBy(data.payload[0].payload.tooltip, 'order').map((item, index) => {
          if (!time && item.time) {
            time = item.time;
          }

          return (
            <div className='areachart_widget__tooltip_item_wrapper' key={item.id || ''}>
              <div className='areachart_widget__tooltip_item_container'>
                <p className='areachart_widget__tooltip_item_title'>{item.name || ''}</p>
                <p className='areachart_widget__tooltip_item_value'>{item.value || item.value === 0 ? item.value : ''}</p>
              </div>
              {index === data.payload[0].payload.tooltip.length - 1
                &&
                <div className='areachart_widget__tooltip_content_time_wrapper'>
                  <p className='areachart_widget__tooltip_content_time_title'>{locale('IDS_LINECHART_TOOLTIP_TIME_TITLE')}</p>
                  <p className='areachart_widget__tooltip_content_time_value'>{time || ''}</p>
                </div>
              }
            </div>
          )
        })
      }

      return null;
    }

    let dataToRender = getTotalDataToRender();

    if (dataToRender) {
      return (
        <div className='areachart_widget__tooltip_wrapper'>
          {dataToRender}
        </div>
      )
    }

    return null;
  }

  return null
}

const XTick = (props: LineChartTickValueType) => {
  const divProps = Object.assign({}, props);
  delete divProps.layout;
  delete divProps.verticalAnchor;
  delete divProps.visibleTicksCount;
  delete divProps.tickFormatter;

  return (
    <text {...divProps} fill='rgba(0, 0, 0, 0.5)' className="bar-chart-tick" dy='20px'>
      {timeParserLineChart({ unix: divProps.payload.value, format: divProps?.format || '' })}
    </text>
  )
}

const YTick = (props: LineChartTickValueType) => {
  const divProps = Object.assign({}, props);
  delete divProps.layout;
  delete divProps.verticalAnchor;
  delete divProps.visibleTicksCount;
  delete divProps.tickFormatter;
  
  return (
    <text {...divProps} fill='rgba(0, 0, 0, 0.5)' className="bar-chart-tick">
      {getTickYFormatedData({ data: divProps.payload.value, dataType: divProps?.format || '' })}
    </text>
  )
};

const totalLineColor = '#5A24C2';

const AreaChartWidget = ({
  data = {},
  selectedId = '',
  firstLoadFetching = false,
}: Props) => {
  const [widgetData, setData] = useState(null);

  useEffect(() => {
    if (isEqual(data, widgetData)) return;
    setData(data);
  }, [data]);

  return (
    <div className='lineChart__wrapper'>
      {firstLoadFetching
        &&
        <div className='lineChart__loader_wrapper'>
          <div className='lineChart__loader'>
            <Loader type='widget' />
          </div>
        </div>
      }
      {!firstLoadFetching
        &&
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            // height={350}
            data={widgetData && widgetData.data ? widgetData.data : []}
            margin={{ top: 15, right: 0, left: 40, bottom: 0 }}
          >
            <defs>
              <filter id='linechart_shadow' height='200%'>
                <feDropShadow dx='0' dy='10' stdDeviation='8' floodColor="rgba(25, 24, 50, 0.35)" floodOpacity="0.5"/>
              </filter>
              <linearGradient id="colorY" x1="0" y1="0" x2="0" y2="1">
                <stop offset="-5.43%" stopColor="#5f35bb" stopOpacity={0.2}/>
                <stop offset="100%" stopColor="#5f35bb" stopOpacity={0}/>
              </linearGradient>
            </defs>
            <CartesianGrid
              height={1}
              stroke='rgba(0, 0, 0, 0.1)'
              horizontal={true}
              vertical={false}
            />
            <XAxis
              dataKey="x"
              type="number"
              tickLine={false}
              // tick={{ fill: '#666666', strokeWidth: 0, dy: 5 }}
              tick={<XTick format={widgetData?.ticksXFormat || ''}/>}
              domain={[widgetData?.timeRange?.startTimeUtc, widgetData?.timeRange?.lastTimeUtc]}
              tickFormatter={(tick) => timeParserLineChart({ unix: tick, format: widgetData?.ticksXFormat || '' })}
              ticks={widgetData && widgetData.ticksX ? widgetData.ticksX : []}
              stroke="transparent"
              fontFamily="Poppins-Regular, sans-serif"
              fontSize='14px'
            />
            <YAxis
              tickLine={false}
              // tick={{ fill: '#666666', strokeWidth: 0, dx: -5 }}
              tick={<YTick format={widgetData?.ticksYFormat || ''}/>}
              tickFormatter={(tick) => getTickYFormatedData({ data: tick, dataType: widgetData?.ticksYFormat || '' })}
              tickMargin={10}
              domain={[]}
              ticks={widgetData?.data?.[0]?.y?.[selectedId]?.[0]?.ticksY || widgetData?.ticksY || []}
              width={widgetData && widgetData.yAxisWidth ? widgetData.yAxisWidth : 60}
              height={16}
              stroke="transparent"
              fontFamily="Poppins-Regular, sans-serif"
              fontSize='14px'
            />
            <Tooltip content={tooltipData => getTooltipData({ selectedId, data: tooltipData, tableData: widgetData?.table || null })} cursor={{ stroke: '#8e9fb2', strokeWidth: 1 }}/>
            {selectedId && widgetData && widgetData.data && widgetData.data[0] && widgetData.data[0].y && typeof widgetData.data[0].y === 'object'
              && widgetData.data[0].y[selectedId].map((selectedItem, index) => {
                if (selectedItem && selectedItem.lineVisible) {
                  return (
                    <Area
                      key={index}
                      type="linear"
                      filter="url(#linechart_shadow)"
                      fill="url(#colorY)"
                      dataKey={selectedId ? `y["${selectedId}"][${index}].y` : `y[${index}].y`}
                      stroke={selectedItem && selectedItem.color ? selectedItem.color : totalLineColor}
                      strokeWidth={4}
                      dot={false}
                    />
                  )
                }

                return null;
              })
            }
            {!selectedId
              &&
              <Area
                type="linear"
                filter="url(#linechart_shadow)"
                fill="url(#colorY)"
                dataKey={`y`}
                stroke={totalLineColor}
                strokeWidth={4}
                dot={false}
              />
            }
            <Area type="monotone" dataKey="prediction" stroke="#562d8e" strokeWidth={4} strokeDasharray="6 6" dot={false} />
          </AreaChart>
        </ResponsiveContainer>
      }
    </div>
  )
}

export default AreaChartWidget;
