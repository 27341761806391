// @flow

import React, { useState } from "react";
import OpenCrypto from "opencrypto";

import "./styles.scss";
import Button from "../Button";

import { useSelector, useDispatch } from "react-redux";

import { savePublickey, whoami } from "../../store/actions";

// import {
//   getWhoAmI,
// } from '../../store/selectors';

const KeyPairGenerator = () => {
  const dispatch = useDispatch();
  const crypt = new OpenCrypto();

  //  const getWhoAmI_data = useSelector(getWhoAmI);

  const [privatKey, setPrivatKey] = useState("");

  function ab2str(buf) {
    return String.fromCharCode.apply(null, new Uint8Array(buf));
  }

  const parseKey = (str) => {
    return str.replaceAll('\n', '')
  }

  const getPublicKeys = (arr) => {
    let publicKeysStr = "";

    arr.forEach((item, i) => {
      publicKeysStr = publicKeysStr + `\n{`;
      const objKeysArr = Object.keys(item);

      for (let key in item) {
        publicKeysStr = publicKeysStr + `\n"${key}": "${key === 'key' ? parseKey(item[key]) : item[key]}"${objKeysArr[objKeysArr.length - 1] !== key ? ',' : ''}`;
      }

      publicKeysStr = publicKeysStr + `\n}${i !== arr.length - 1 ? ',' : ''}`;
    });

    return publicKeysStr;
  }

  const createJSON = (response, keyPair, whoami) => {
    crypt.cryptoPrivateToPem(keyPair.privateKey).then(privatePem => {
   //   console.log(privatePem)
   // })
  //  window.crypto.subtle.exportKey("pkcs8", keyPair.privateKey).then((e) => {
      crypt
        .getFingerprint(keyPair.privateKey)
        .then((fingerprint) => {
          return fingerprint;
        })
        .then((fingerprint) => {
        //  const exportedAsString = ab2str(e);
        //  const exportedAsBase64 = window.btoa(exportedAsString);
        
          const formatted = privatePem.replace(/(\r\n|\n|\r)/gm, "\\n"); // `-----BEGIN PRIVATE KEY-----${exportedAsBase64}-----END PRIVATE KEY-----`;
         
          let responseString = "";

          if (response) {
            for (let key in response) {
              responseString =
                responseString + `\n"${key}": "${response[key]}",`;
            }
          }

          if (whoami) {
            responseString = responseString + `\n"publicKeys": [${getPublicKeys(whoami?.publicKeys || [])}\n],`
          }

          responseString =
            responseString +
            `\n"private_key_id": "${fingerprint}",\n"private_key": "${formatted}", \n"url": "${window.location.href}", \n"auth_uri": "", \n"token_uri": "",\n"auth_provider_x509_cert_url": "",\n"client_x509_cert_url": ""`;
          setPrivatKey(formatted);
          var a = window.document.createElement("a");
          a.href = window.URL.createObjectURL(
            new Blob(["{" + responseString + "\n}"], {
              type: "text/plain;charset=utf-8;",
            })
          );
          a.download = "account_settings.json";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        });
    });
  };

  const generateKey = () => {
    window.crypto.subtle
      .generateKey(
        {
          name: "RSA-OAEP",
          // Consider using a 4096-bit key for systems that require long-term security
          modulusLength: 2048,
          publicExponent: new Uint8Array([1, 0, 1]),
          hash: "SHA-256",
        },
        true,
        ["encrypt", "decrypt"]
      )
      .then((keyPair) => {
        crypt.cryptoPublicToPem(keyPair.publicKey).then((publicKey) => {
          const formatted = publicKey.replace(/(\r\n|\n|\r)/gm, "\n");

          dispatch(savePublickey(formatted)).then((response) => {
            dispatch(whoami()).then((whoami) => {
              createJSON(response, keyPair, whoami);
            });
          });
        });
      });
  };

  return (
    <div className="keyPairGenerator__container_wrapper">
      <div>RSA Key Generator</div>
      <div className="content_wrapper">
        <div className="generate_btn_container">
          <Button onClick={generateKey} caption="generate new keys" />
        </div>
        <div className="generate_content_container">
          PRIVATE KEY
          <div className="privat_key">{privatKey}</div>
          Please save key it will be destroyed if you close this page
        </div>
      </div>
    </div>
  );
};

export default KeyPairGenerator;
