import moment from 'moment';
import max from 'lodash/max';
import get from 'lodash/get';

import { convertBytes } from '../../commonExtensions/bytesParser';
import { reducer } from '../../commonExtensions/common';
import locale from '../../locale';

import { timeParserUnixToDate } from '../../commonExtensions/timeParser';

export const linechartPeriodOpt = [
  { value: 'live', label: locale('IDS_PERFOMANCE_PERIOD_LIVE_LABEL') },
  { value: 'hour', label: locale('IDS_PERFOMANCE_PERIOD_HOUR_LABEL') },
  { value: 'day', label: locale('IDS_PERFOMANCE_PERIOD_DAY_LABEL') },
  { value: 'week', label: locale('IDS_PERFOMANCE_PERIOD_WEEK_LABEL') },
  { value: 'month', label: locale('IDS_PERFOMANCE_PERIOD_MONTH_LABEL') },
];

const perfCounterName = {
  bandwidth: {
    upload: 'Upload rate',
    download: 'Download rate',
  },
  storage: {
    consumed: 'Consumed capacity',
    total: 'Total capacity',
  },
};

const getTicksYIntervalArr = (maxValue) => {
  let ticksInterval = maxValue / 5;
  let ticksY = [];

  if (ticksInterval) {
    for (let i = 0; i <= 5; i++) {
      ticksY.push(typeof ticksInterval === 'number' && ticksInterval >= 0 ? +(ticksInterval * i).toFixed(1) : `*${ticksInterval}`);
    }
  } else {
    for (let i = 0; i <= 5; i++) {
      ticksY.push(0 + i);
    }
  }

  return ticksY;
}

export const getTickYFormatedData = ({ data, dataType }) => {
  let dataToRender = `${data}`;

  if (data || data === 0) {
    if (dataType === 'bytes') {
      dataToRender = (data || data >= 0) ? convertBytes(data) : `*${data}`;
    } else if (dataType === 'bytes/s') {
      dataToRender = (data || data >= 0) ? `${convertBytes(data)}/s` : `*${data}`;
    } else if (dataType === '%') {
      dataToRender = `${(data || data >= 0) && typeof data === 'number' ? +(data).toFixed(1) : `*${data}`}%`;
    } else if (dataType === 'ms') {
      dataToRender = `${(data || data >= 0) && typeof data === 'number' ? +(data).toFixed(1) : `*${data}`} ms`;
    } else if (dataType === 'op/s') {
      dataToRender = `${(data || data >= 0) && typeof data === 'number' ? +(data).toFixed(1) : `*${data}`} Op/s`;
    } else {
      dataToRender = `${(data || data >= 0) && typeof data === 'number' ? +(data).toFixed(1) : `*${data}`}`;
    }
  }

  return data || data === 0 ? dataToRender : 'N/D';
}

export const getTooltipName = ({ name }) => {
  if (name === perfCounterName.bandwidth.upload) {
    return locale('IDS_PERFOMANCE_BANDWIDTH_TOOLTIP_UPLOAD_LABEL');
  } else if (name === perfCounterName.bandwidth.download) {
    return locale('IDS_PERFOMANCE_BANDWIDTH_TOOLTIP_DOWNLOAD_LABEL');
  } else if (name === perfCounterName.storage.consumed) {
    return locale('IDS_PERFOMANCE_STORAGE_TOOLTIP_CONSUMED_LABEL');
  } else if (name === perfCounterName.storage.total) {
    return locale('IDS_PERFOMANCE_STORAGE_TOOLTIP_TOTAL_LABEL');
  }

  return name; 
}

const getTooltipOrderIndex = ({ chartType, dataName }) => {
  let counterValuesArr = (Object.values(perfCounterName[chartType]));

  return counterValuesArr.indexOf(dataName) || 0;
}

export const getXTickFormat = ({ period }) => {
  let defaultFormat = 'HH:mm';

  if (period === 'live') {
    defaultFormat = 'HH:mm';
  } else if (period === 'hour') {
    defaultFormat = 'HH:mm';
  } else if (period === 'day') {
    defaultFormat = 'HH:mm';
  } else if (period === 'week') {
    defaultFormat = 'dddd';
  } else if (period === 'month') {
    defaultFormat = 'MMM D';
  } else if (period === 'year') {
    defaultFormat = 'MMM D[,] YY';
  }

  return defaultFormat;
}

export const getLineChartModifiedData = ({ data, period, dataType, chartType }) => {
  let newData = [];
  let modifiedData = null;

  const getStartTime = ({ data, interval, timePeriod }) => {
    let tick = Number(data.timeRange.startTimeUtc);
    let firstTick = interval;
    let secondTick = moment(moment.unix(Number(tick)).add(firstTick, timePeriod)).unix();
  
    return secondTick;
  }

  const getTicks = ({ data, interval, timePeriod }) => {
    let ticksArr = [];
    let tick = Number(data.timeRange.startTimeUtc);
    let secondTick = getStartTime({ data, interval, timePeriod });
  
    ticksArr.push(tick);
  
    if (timePeriod === 'month') {
      if (secondTick) {
        ticksArr.push(secondTick);
        while (secondTick < Number(data.timeRange.endTimeUtc)) {
          secondTick = moment(moment.unix(Number(secondTick)).add(interval, timePeriod).startOf('month')).unix();
          ticksArr.push(secondTick);
        }
  
        if (secondTick > Number(data.timeRange.endTimeUtc)) {
          ticksArr[ticksArr.length - 1] = Number(data.timeRange.endTimeUtc)
        }
      } else {
        while (tick < Number(data.timeRange.endTimeUtc)) {
          tick =  moment(moment.unix(Number(tick)).add(interval, timePeriod).startOf('month')).unix();
          ticksArr.push(tick);
        }
  
        if (tick > Number(data.timeRange.endTimeUtc)) {
          ticksArr[ticksArr.length - 1] = Number(data.timeRange.endTimeUtc)
        }
      }
    } else {
      if (secondTick) {
        ticksArr.push(secondTick);
        while (secondTick < Number(data.timeRange.endTimeUtc)) {
          secondTick = moment(moment.unix(Number(secondTick)).add(interval, timePeriod)).unix();
          ticksArr.push(secondTick);
        }
  
        if (secondTick > Number(data.timeRange.endTimeUtc)) {
          ticksArr[ticksArr.length - 1] = Number(data.timeRange.endTimeUtc)
        }
      } else {
        while (tick < Number(data.timeRange.endTimeUtc)) {
          tick =  moment(moment.unix(Number(tick)).add(interval, timePeriod)).unix();
          ticksArr.push(tick);
        }
  
        if (tick > Number(data.timeRange.endTimeUtc)) {
          ticksArr[ticksArr.length - 1] = Number(data.timeRange.endTimeUtc)
        }
      }
    }
    
    return ticksArr;
  }

  const getXTicksArr = ({ data, period }) => {
    let ticksArr = [];
  
    if (period === 'live') {
      ticksArr = getTicks({ data, interval: 3, timePeriod: 'minutes'});
    } else if (period === 'hour') {
      ticksArr = getTicks({ data, interval: 5, timePeriod: 'minutes'});
    } else if (period === 'day') {
      ticksArr = getTicks({ data, interval: 2, timePeriod: 'hours'});
    } else if (period === 'week') {
      ticksArr = getTicks({ data, interval: 1, timePeriod: 'days'});
    } else if (period === 'month') {
      ticksArr = getTicks({ data, interval: 2, timePeriod: 'days'});
    } else if (period === 'year') {
      ticksArr = getTicks({ data, interval: 1, timePeriod: 'month'});
    }
  
    return ticksArr;
  }

  const getYData = (data) => {
    if (data === null) {
      return null;
    } else {
      return data;
    }
  };

  const getYLineChartValue = ({ data, dataHeader, chartType }) => {
    if (chartType === 'bandwidth') {
      return getYData(data.reduce(reducer));
    }

    if (chartType === 'storage') {
      let dataIndex = dataHeader?.find(item => item.context === perfCounterName[chartType].consumed)?.dataIndex;
    
      return getYData(data[dataIndex]);
    }
  }

  const getTooltipValue = ({ dataValue, dataHeader, dataType, chartType }) => {
    let tooltipValue = [];

    let actualDataHeader = [];

    if (chartType === 'bandwidth') {
      actualDataHeader = dataHeader;
    } else if (chartType === 'storage') {
      actualDataHeader = [dataHeader?.find(item => item.context === perfCounterName.storage.consumed)];
    }
    
    actualDataHeader.forEach((item, index) => {
      tooltipValue.push({
        id: item.dataIndex,
        order: getTooltipOrderIndex({ chartType, dataName: item.context }),
        name: getTooltipName({ name: item.context }),
        value: getTickYFormatedData({ data: dataValue.data[item.dataIndex], dataType }),
        time: typeof dataValue.timestampUtc === 'number' ? timeParserUnixToDate({ unix: dataValue.timestampUtc }) : '',
      });
    });

    return tooltipValue;
  }

  if (data && data.data) {
    data.data.forEach((dataValue) => {
      newData.push({
        x: dataValue.timestampUtc,
        y: getYLineChartValue({
          data: dataValue.data,
          dataHeader: data.dataHeader,
          chartType,
        }),
        prediction: null,
        tooltip: getTooltipValue({ dataValue, dataHeader: data.dataHeader, dataType, chartType }),
      })
    })

    let maxValue = 0;

    if (chartType === 'bandwidth') {
      maxValue = max(data.dataHeader.map(item => get(item, 'valuesRange.maxValue', 0))) * 2;
    } else if (chartType === 'storage') {
      maxValue = (data.dataHeader?.find(item => item.context === perfCounterName.storage.consumed)?.valuesRange?.maxValue || 0) * 2;
    }

    let ticksY = getTicksYIntervalArr(maxValue);

    function collectLineChartData ({
      data,
      timeRange,
      ticksX,
      ticksY,
      ticksXFormat,
      ticksYFormat,
      yAxisWidth,
    }) {
      this.data = data;
      this.timeRange = timeRange;
      this.ticksX = ticksX;
      this.ticksY = ticksY;
      this.ticksXFormat = ticksXFormat;
      this.ticksYFormat = ticksYFormat;
      this.yAxisWidth = yAxisWidth;
    }

    const getYAxisWidth = ({ ticksYArr, dataType }) => {
      let width = 15;
      let transformedDataArr = [];

      // let re = /[ ./]/g;

      ticksYArr.forEach(item => {
        // let newStr = getTickYFormatedData({ data: item, dataType }).split(re); // split by groups
        let newStr = getTickYFormatedData({ data: item, dataType }).split(''); // split every letter
      
        transformedDataArr.push(newStr.join('').length)
      });

      Reflect.defineProperty(Array.prototype, 'max', {
        get: function () {
          return Math.max(...this);
        }
      });
      
      for (let i = 0; i < transformedDataArr.max; i++) {
        width += 5;
      }

      return width;
    }

    modifiedData = new collectLineChartData({
      data: newData,
      timeRange: data.timeRange,
      ticksX: getXTicksArr({ data, period }),
      ticksY: ticksY,
      ticksXFormat: getXTickFormat({ period }),
      ticksYFormat: dataType,
      yAxisWidth: getYAxisWidth({ ticksYArr: ticksY, dataType}),
    });
  }

  return modifiedData;
};
