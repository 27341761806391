import React from 'react';
import Loader from '../Loader';

import { stringToLowerCase } from '../../commonExtensions/common';

import './styles.scss';

type Props = {
  data: string,
};

const getIcon = (data) => {
  let status = stringToLowerCase(data);
  console.log(status)

  if (status === 'ok') return 'status_icon__wrapper--ok';
  if (status === 'warning') return 'status_icon__wrapper--warning';
  if (status === 'repairing') return 'status_icon__wrapper--warning';

  return '';
}

const StatusIcon = ({
  data,
}: Props) => (
  <>
    {stringToLowerCase(data) === 'inprogress' ?
    <Loader /> :
    <div className={`status_icon__wrapper ${getIcon(data)}`} />}
  </>
);

export default StatusIcon;
