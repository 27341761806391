const strings = {
  IDS_COMMON_METRIC_MB: 'MB',
  IDS_COMMON_METRIC_MIB: 'MiB',
  IDS_COMMON_METRIC_GB: 'GB',
  IDS_COMMON_METRIC_GIB: 'GiB',
  IDS_COMMON_BTN_CAPTION_CLOSE: 'Close',
  IDS_COMMON_BTN_CAPTION_DOWNLOAD: 'Download',
  IDS_SIDEBAR_DASHBOARD_LINK: 'Dashboard',
  IDS_SIDEBAR_CONTAINERS_LINK: 'Containers',
  IDS_SIDEBAR_FILES_LINK: 'Files',
  IDS_SIDEBAR_BILLING_LINK: 'Billing',
  IDS_SIDEBAR_SETTINGS_LINK: 'Settings',
  IDS_SIDEBAR_ACCOUNT_LINK: 'Account',
  IDS_SIDEBAR_USERS_LINK: 'Users',
  IDS_LOGO_STARWIND_TITLE: 'StarWind',
  IDS_LOGO_DS_TITLE: 'DS',
  IDS_HEADER_SEARCH_PLACEHOLDER_LABEL: 'Search of containers or files',
  IDS_EVENTS_POPUP_WIDGET_EMPTY_MSG: 'No Events',
  IDS_NOTIFICATIONS_POPUP_WIDGET_EMPTY_MSG: 'No Notifications',
  IDS_DASHBOARD_TITLE: 'Dashboard',
  IDS_DASHBOARD_TOTAL_WIDGET_CONTAINERS_TITLE: 'Containers',
  IDS_DASHBOARD_TOTAL_WIDGET_MONTHLY_PAYMENT_TITLE: 'Current Balance',
  IDS_DASHBOARD_TOTAL_WIDGET_FILES_TITLE: 'Files',
  IDS_DASHBOARD_TOTAL_WIDGET_USEDCAPACITY_TITLE: 'Used capacity',
  IDS_DASHBOARD_TOTAL_WIDGET_TOTALBANDWIDTH_TITLE: 'Total Bandwidth',
  IDS_DASHBOARD_LINECHART_GRAPHS_TITLE: 'Graphs',
  IDS_DASHBOARD_LINECHART_WIDGET_STORAGE_TITLE: 'Storage',
  IDS_DASHBOARD_LINECHART_WIDGET_BANDWIDTH_TITLE: 'Bandwidth',
  IDS_DASHBOARD_LINECHART_WIDGET_TOTALPERIOD_TITLE: 'Total for the period:',
  IDS_DASHBOARD_TABLE_HEADER_ACTION_TITLE: 'Action',
  IDS_DASHBOARD_TABLE_HEADER_USER_TITLE: 'User',
  IDS_DASHBOARD_TABLE_HEADER_IPADDRESS_TITLE: 'IP address',
  IDS_DASHBOARD_TABLE_HEADER_DATEANDTIME_TITLE: 'Date and time',
  IDS_FILES_TITLE: 'Files',
  IDS_FILES_UPLOADFILE_BTN_CAPTION: 'Upload file',
  IDS_FILES_DOWNLOADFILE_BTN_CAPTION: 'Download file',
  IDS_FILES_DOWNLOADFILE_WIZARD_TITLE: 'Download file',
  IDS_FILES_DOWNLOADFILE_WIZARD_PATH_LABEL: 'Folder path',
  IDS_FILES_TABLE_HEADER_NAME_TITLE: 'Name',
  IDS_FILES_TABLE_HEADER_SIZE_TITLE: 'Size',
  IDS_FILES_TABLE_HEADER_LASTMODIFIED_TITLE: 'Last modified',
  IDS_FILES_TABLE_HEADER_OFFLINE_TITLE: 'Locality',
  IDS_FILES_PROPS_WIDGET_TITLE: 'Properties',
  IDS_FILES_PROPS_WIDGET_SIZE_TITLE: 'Size',
  IDS_FILES_PROPS_WIDGET_OWNER_TITLE: 'Owner',
  IDS_FILES_PROPS_WIDGET_LASTMODIFIED_TITLE: 'Last modified',
  IDS_FILES_FRAGMENTS_WIDGET_TITLE: 'File fragments',
  IDS_FILES_FRAGMENTS_WIDGET_CURRENTSTATUS_TITLE: 'Current status',
  IDS_FILES_FRAGMENTS_WIDGET_DETAILS_TITLE: 'Details',
  IDS_FILES_FRAGMENTS_WIDGET_DISTRIBUTION_TITLE: 'Distribution',
  IDS_FILES_FRAGMENTS_WIDGET_DISTRIBUTION_NODESONLINE_MSG: 'nodes online',
  IDS_FILES_FRAGMENTS_WIDGET_AVAILABILITY_TITLE: 'Availability',
  IDS_FILES__TABLE_STATE_CREATED_TITLE: 'Created',
  IDS_FILES__TABLE_STATE_SCALING_TITLE: 'Scaling',
  IDS_FILES__TABLE_STATE_DOWNLOADING_TITLE: 'Downloading',
  IDS_FILES__TABLE_STATE_DEGRADED_TITLE: 'Degraded',
  IDS_FILES__TABLE_STATE_REPAIRING_TITLE: 'Repairing',
  IDS_FILES__TABLE_STATE_OK_TITLE: 'Ok',
  IDS_FILES_PLACEMENT_WIDGET_TITLE: 'Placement',
  IDS_LINECHART_TOOLTIP_TIME_TITLE: 'Time:',
  IDS_PERFOMANCE_PERIOD_LIVE_LABEL: 'Live',
  IDS_PERFOMANCE_PERIOD_HOUR_LABEL: 'Hour',
  IDS_PERFOMANCE_PERIOD_DAY_LABEL: 'Day',
  IDS_PERFOMANCE_PERIOD_WEEK_LABEL: 'Week',
  IDS_PERFOMANCE_PERIOD_MONTH_LABEL: 'Month',
  IDS_PERFOMANCE_BANDWIDTH_TOOLTIP_UPLOAD_LABEL: 'Upload',
  IDS_PERFOMANCE_BANDWIDTH_TOOLTIP_DOWNLOAD_LABEL: 'Download',
  IDS_PERFOMANCE_STORAGE_TOOLTIP_CONSUMED_LABEL: 'Consumed',
  IDS_PERFOMANCE_STORAGE_TOOLTIP_TOTAL_LABEL: 'Total',
};
 
const locale = (str) => {
  return strings[str] || `*${str}`;
}

export default locale;
