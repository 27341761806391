// @flow

import React from 'react';

import './styles.scss';

type Props = {
  title: string,
  headerColor: string,
  additionalClassName: string,
  styles: any,
  onBtnCloseClick: () => void,
};

const ModalWindow = ({
  children = null,
  title = '',
  styles = null,
  headerColor = '',
  onBtnCloseClick = () => {},
  btnCloseHidden = false,
  additionalClassName = '',
}: Props) => (
    <div className={`modal_window__overlay_wrapper ${additionalClassName || ''}`}>
      <div className='modal_window__wrapper'>
        <div style={styles} className='modal_window__container'>
          <div
            className='modal_window__header_wrapper'
            style={headerColor ? { backgroundColor: headerColor } : null}
          >
            <h2 className='modal_window__header_title'>{title}</h2>
            {!btnCloseHidden &&
              <button className='modal_window__header_close_btn' onClick={onBtnCloseClick} />}
          </div>
          <div className='modal_window__content_wrapper'>
            {children}
          </div>
        </div>
      </div>
    </div>
  )

export default ModalWindow;