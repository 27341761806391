import { createSelector } from 'reselect';
import { reducer } from '../../commonExtensions/common';
import { convertBytes } from '../../commonExtensions/bytesParser';
import { getLineChartModifiedData } from '../../components/LineChartWidget/lineChartExtensions';

const getDashCounterValue = state => state.dashboard.value;
const getDashList = (state) => {
  console.log('check for updates')
  return state.dashboard.dashItems 
};

export const getCouterValue = createSelector(getDashCounterValue, (value) => {
  return value;
});


export const getDashListSelector = createSelector(getDashList, (list) => {
  console.log('run function if data changed')
  return list.map((item) => {
    return item;
  });
});

const bandwidthUsage = state => state.dashboard?.widget_info?.find(item => item.name === 'bandwidthUsage');
const storageUsage = state => state.dashboard?.widget_info?.find(item => item.name === 'storageUsage');
const dashboardChartPeriodValue = state => state.dashboard.chartPeriod;
const accountInfo = state => state.dashboard?.widget_info?.find(item => item.name === 'accountInfo');

export const getPaymentInfo = createSelector(accountInfo, (value) => {
  return value?.data?.balance;
});

export const getDashboardChartPeriodValue = createSelector(dashboardChartPeriodValue, (value) => {
  return value;
});

export const getWidgetData_bandwidth = createSelector(bandwidthUsage, dashboardChartPeriodValue, (value, period) => {
  return getLineChartModifiedData({
    data: value?.data,
    period,
    dataType: 'bytes/s',
    chartType: 'bandwidth',
  });
});

export const getWidgetData_storage = createSelector(storageUsage, dashboardChartPeriodValue, (value, period) => {
  return getLineChartModifiedData({
    data: value?.data,
    period,
    dataType: 'bytes',
    chartType: 'storage',
  });
});

export const getTotalPeriodBandwidth = createSelector(bandwidthUsage, (value) => {

  let dataArr = value?.data?.data?.map(item => item?.data)?.flat() || [];

  if (dataArr && dataArr.length > 0 && Array.isArray(dataArr)) {
    return convertBytes( dataArr.reduce(reducer));
  }
  else {
    return '0'
  }
});

// export const getDashListSelector = (state) => {
//   console.log('start function to get unchanged data')
//   return state.dashboard.dashItems.filter(item => true);
// };
