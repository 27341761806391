import * as types from '../actions/actionTypes';

const initialState = {
  who_am_i: {
    login: '',
  }
}

const settings = (state = initialState, action) => {
  switch (action.type) {
    case types.WHO_AM_I_DATA:
      return {...state, who_am_i: action.payload };
    default:
      return state;
  }
}

export default settings;