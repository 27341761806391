//flow

import React from 'react';

import locale from '../../locale';

import './styles.scss';

type Props = {
  data: {
    size: string,
    owner: string,
    lastModified: string,
  }
};

const FilePropsWidget = ({
  data,
}: Props) => (
  <div className='file_props__wrapper'>
    <h2 className='file_props__title'>{locale('IDS_FILES_PROPS_WIDGET_TITLE')}</h2>
    <div className='file_props__main_wrapper'>
      <div className='file_props__content_wrapper'>
        <p className='file_props__content_title'>{locale('IDS_FILES_PROPS_WIDGET_SIZE_TITLE')}</p>
        <p title={data?.size || ''} className='file_props__content_total'>{data?.size || ''}</p>
      </div>
      <div className='file_props__content_wrapper'>
        <div className='file_props__content_block'>
          <p className='file_props__content_title file_props__content_title--mb2'>{locale('IDS_FILES_PROPS_WIDGET_OWNER_TITLE')}</p>
          <p className='file_props__content_value'>{data?.owner || ''}</p>
        </div>
        <div className='file_props__content_block'>
          <p className='file_props__content_title file_props__content_title--mb2'>{locale('IDS_FILES_PROPS_WIDGET_LASTMODIFIED_TITLE')}</p>
          <p className='file_props__content_value'>{data?.lastModified || ''}</p>
        </div>
      </div>
    </div>
  </div>
);

export default FilePropsWidget;