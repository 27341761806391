import * as types from '../actions/actionTypes';

const initialState = {
  files: null,
  file_info:null,
}

const files = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_FILES:
      return {...state, files: action.payload };
      case types.SET_FILES_INFO:
      return {...state, file_info: action.payload };
    default:
      return state;
  }
}

export default files;