

const initialUserState =
{  emulate2: [],
  showAlert2:'',
  showAlert:''

}
;
const AlertReducer = function(state = initialUserState, action) {


  switch(action.type) {

  case 'SHOW_ALERT':
		 return Object.assign({}, state, { showAlert: action.data });
 break;




  default:

  return state;
  }
}
export default AlertReducer
