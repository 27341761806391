// @flow

import React from 'react';

import './styles.scss';

type Props = {
  name: string,
  theme: string,
  type: 'button' | 'submit',
  caption: string,
  active: boolean,
  disabled: boolean,
  onClick: (e: Event)=> void,
};

const ButtonWizard = ({
  name = 'button',
  type = 'button',
  caption = '',
  active = false,
  disabled = false,
  onClick = () => {},
}: Props) => (
  <button
    type={type}
    name={name}
    disabled={disabled}
    className={`button_wizard__wrapper ${active ? 'button_wizard__wrapper--active' : ''} ${disabled ? 'button_wizard__wrapper--disabled' : ''}`}
    onClick={(e) => {
      if (!disabled) onClick(e);

      e.preventDefault();
    }}
  >
    {caption}
  </button>
);

export default ButtonWizard;