// @flow

import React, { useState } from 'react';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';

import './styles.scss';

type Props = {
  warningText: String,
  onLoginBtnClick: Function,
};

const Login = ({
  warningText = '',
  onLoginBtnClick,
  onRegisterBtnClick
}: Props) => {
  const [loginInputValue, setLoginInputValue] = useState('');
  const [passInputValue, setPassInputValue] = useState('');
  const [emailValue, setEmailInputValue] = useState('');
  const [rememberCredChecked, setRememberCredChecked] = useState(false);

  const [registrationForm, setRegistrationForm] = useState(false)

  const onLoginInputChange = (e) => {
    setLoginInputValue(e.target.value);
  }

  const onPassInputChange = (e) => {
    setPassInputValue(e.target.value);
  }

  const onEmailInputChange = (e) => {
    setEmailInputValue(e.target.value);
  }


  const onRememberCredChecked = () => {
    setRememberCredChecked(!rememberCredChecked);
  }

  const onLoginConfirm = (e) => {
    
    if (loginInputValue && passInputValue && !warningText && !registrationForm) {
      onLoginBtnClick({
        login: loginInputValue,
        password: passInputValue,
      });
    }

    if (loginInputValue && passInputValue && emailValue && !warningText && registrationForm) {
      onRegisterBtnClick({
        login: loginInputValue,
        password: passInputValue,
        email: emailValue,
      });
    }

    e.preventDefault();
  }

  return (
    <div className='login__wrapper'>
      <div className='logo'><span>Green</span> Storage</div>
      <h1 className='login__title'>Personal Account</h1>
      <div className='login__content_wrapper'>
        {registrationForm ?
        
        <>
        <p className='login__input_title'>E-mail</p>
        <div className='login__input_wrapper'>
          <Input
            name='email input'
            type='text'
            placeholder={'example@domain.com'}
            value={emailValue}
            warning={warningText}
            onChange={onEmailInputChange}
          />
        </div>
        </>
        
        
        : null}
        <p className='login__input_title'>Login</p>
        <div className='login__input_wrapper'>
          <Input
            name='login input'
            type='text'
            placeholder={'example@'}
            value={loginInputValue}
            warning={warningText}
            onChange={onLoginInputChange}
          />
        </div>
        <p className='login__input_title'>Password</p>
        <div className='login__input_wrapper'>
          <Input
            name='password input'
            type='password'
            value={passInputValue}
            showPassBtn
            warning={warningText}
            onChange={onPassInputChange}
          />
        </div>
        <div className='login__checkbox_container_wrapper'>
          <label className='login__checkbox_label_wrapper'>
            <Checkbox
              name='remember credentials checkbox'
              id='remember_cred'
              checked={rememberCredChecked}
              onChange={onRememberCredChecked}
              additionalClassName={'login__checkbox_style'}
            />
            <p className='login__checkbox_label_text'>Remember my credentials</p>
          </label>
          <p onClick={()=> setRegistrationForm(!registrationForm) } className='login__forgot_pass_link'>
            {registrationForm ? 'Sign in' : 'Register'}
           
            </p>
        </div>
        <div className='login__warning_wrapper'>
          {warningText &&
            <>
              <div className='login__warning_icon' />
              <p className='login__warning_text'>{warningText}</p>
            </>}
        </div>
        <div className='login__button_wrapper'>
          <Button
            name='submit login button'
            type='submit'
            caption={registrationForm ? 'Register' : 'Sign in'}
            onClick={onLoginConfirm}
            disabled={warningText}
          />
        </div>
      </div>
    </div>
  )
}

export default Login;