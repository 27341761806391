// @flow

import React from 'react';
import Select, { components } from 'react-select';

import './styles.scss';

type Props = {
  options: Array<{
    value: any,
    label: string,
  }>,
  selected: {
    value: any,
    label: string,
  } | null,
  placeholder?: string,
  clearable: boolean,
  deleteRemoves: boolean,
  backspaceRemoves: boolean,
  searchable: boolean,
  onSelectOpt: (value: { value: any, label: string }) => void,
  disabled: boolean,
  animated?: boolean,
}

const menuScroll = {
  '::-webkit-scrollbar': {
    width: '4px',       /* width of the entire scrollbar */
  },
  '::-webkit-scrollbar-track': {
    margin: '5px 0',
    borderRadius: '2px',
    background: '#e3e7f0',       /* color of the tracking area */
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '2px',
    background: '#B5B9BF',  /* creates padding around scroll thumb */
  },
};

const getTableStyleOptionBgColor = (state) => {
  if (state?.isDisabled) return '#F6F6F6';
  if (state?.isFocused) return '#FAF9FC';

  return '#ffffff';
}

const getTableStyleOptionTextColor = (state) => {
  if (state?.isDisabled) return '#8E9FB2';
  if (state?.isFocused) return '#562D8E';

  return '#212121';
}

const getTableStyleSelectedColor = (state) => {
  if (state?.isDisabled) return '#8E9FB2';
  if (state?.isFocused) return '#2B2B2B';
  if (state?.selectProps?.menuIsOpen) return '#562D8E';

  return '#2B2B2B';
}

const tableBoxStyles = (disabled) => ({
  container: (provided, state) => ({
    ...provided,
    height: '100%',
  }),
  option: (provided, state) => ({
    ...provided,
    color: getTableStyleOptionTextColor(state),
    fontFamily: 'Inter-Regular',
    fontSize: '16px',
    lineHeight: '19px',
    backgroundColor: getTableStyleOptionBgColor(state),
    border: 'none',
    cursor: 'pointer',
    ':active': {
      color: state.isDisabled ? '#8E9FB2' : '#472575',
      backgroundColor: state.isDisabled ? '#8E9FB2' : '#F9F7FC',
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    marginTop: '4px',
    marginBottom: '4px',
    border: '1px solid #562D8E',
    borderRadius: '1px',
    boxShadow: '0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -5px rgba(24, 39, 75, 0.12)',
  }),
  menuList: (provided, state) => ({
    ...provided,
    ...menuScroll,
    padding: '0',
  }),
  control: (provided, state) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    // minHeight: '30px',
    // maxHeight: '30px',
    backgroundColor: '#F9F7FC',
    border: '1px solid #562D8E',
    boxShadow: 'unset',
    borderRadius: '1px',
    boxSizing: 'border-box',
    outline: '0 !important',
    transition: 'all 100ms',
    cursor: 'pointer',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: '#212121',
    fontFamily: 'Inter-Regular',
    fontSize: '16px',
    lineHeight: '19px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    minWidth: '15px',
    width: '15px',
    minHeight: '15px',
    height: '15px',
    padding:' 0',
    color: getTableStyleSelectedColor(state),
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    ':hover': {
      color: state.isDisabled ? '#8E9FB2' : '#2B2B2B',
    },
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    position: 'absolute',
    right: '16px',
    bottom: state.selectProps.menuIsOpen ? '8px' : '13px',
    minWidth: '10px',
    width: '10px',
    minHeight: '10px',
    height: '10px',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    paddingLeft: '16px',
    paddingRight: '10px',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#212121',
    fontFamily: 'Inter-Regular',
    fontSize: '16px',
    lineHeight: '19px',
    margin: '0 10px 0 0',
    ':hover': {
      color: state.isDisabled ? '#8E9FB2' : '#472575',
    },
  })
})

const getCustomStyleOptionBgColor = (state) => {
  if (state?.isDisabled) return '#F6F6F6';
  if (state?.isFocused) return '#FAF9FC';

  return '#ffffff';
}

const getCustomStyleOptionTextColor = (state) => {
  if (state?.isDisabled) return '#8E9FB2';
  if (state?.isFocused) return '#562D8E';

  return '#191833';
}

const getCustomStyleSelectedColor = (state) => {
  if (state?.isDisabled) return '#8E9FB2';
  if (state?.isFocused) return '#472575';
  if (state?.selectProps?.menuIsOpen) return '#191833';

  return '#191833';
}

const customStyles = (disabled) => {
  return {
    container: (provided, state) => ({
      ...provided,
      height: '100%',
    }),
    option: (provided, state) => ({
      ...provided,
      color: getCustomStyleOptionTextColor(state),
      fontFamily: 'Poppins-SemiBold',
      fontSize: '16px',
      lineHeight: '19px',
      backgroundColor: getCustomStyleOptionBgColor(state),
      borderBottom: '1px solid #E3E7F0',
      cursor: 'pointer',
      ':active': {
        color: state.isDisabled ? '#8E9FB2' : '#472575',
        backgroundColor: state.isDisabled ? '#F6F6F6' : '#F9F7FC',
      },
      ':last-child': {
        border: 'none',
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      marginTop: '4px',
      borderRadius: '1px',
      boxShadow: '0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -5px rgba(24, 39, 75, 0.12)',
    }),
    menuList: (provided, state) => ({
      ...provided,
      ...menuScroll,
      padding: '0',
    }),
    control: (provided, state) => ({
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      // minHeight: '30px',
      // maxHeight: '30px',
      backgroundColor: 'rgba(255, 255, 255, 0.85)',
      border: state.isDisabled ? '1px solid #E3E7F0' : '1px solid #C7BADA',
      borderRadius: '10px',
      boxShadow: state.isFocused ? '0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -5px rgba(24, 39, 75, 0.12)' : 'unset',
      boxSizing: 'border-box',
      outline: '0 !important',
      transition: 'all 100ms',
      cursor: 'pointer',
      ':hover': {
        boxShadow: !state.isDisabled ? '0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -5px rgba(24, 39, 75, 0.12)' : 'unset',
      }
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: '#646b77',
      fontFamily: 'Inter-Regular',
      fontSize: '13px',
      lineHeight: '15px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      minWidth: '15px',
      width: '15px',
      minHeight: '15px',
      height: '15px',
      padding:' 0',
      color: getCustomStyleSelectedColor(state),
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
      ':hover': {
        color: state.isDisabled ? '#8E9FB2' : '#472575',
      },
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      position: 'absolute',
      top: state.selectProps.menuIsOpen ? '18px' : '14px',
      right: '20px',
      minWidth: '15px',
      width: '15px',
      minHeight: '15px',
      height: '15px',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      paddingLeft: '20px',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: getCustomStyleSelectedColor(state),
      fontFamily: 'Poppins-SemiBold',
      fontSize: '18px',
      lineHeight: '27px',
      ':hover': {
        color: state.isDisabled ? '#8E9FB2' : '#472575',
      },
    })
  }
}

const getAnimatedStyleSelectedColor = (state) => {
  if (state?.isDisabled) return '#3D444D';
  if (state?.isFocused) return '#2B2B2B';
  if (state?.selectProps?.menuIsOpen) return '#2B2B2B';

  return '#2B2B2B';
}

const getAnimatedStyleOptionBgColor = (state) => {
  if (state?.isDisabled) return '#F6F6F6';
  if (state?.isFocused) return '#B4E4FF';

  return '#ffffff';
}

const animatedStyles = (disabled, warning) => {
  return {
    container: (provided, state) => ({
      ...provided,
      height: '100%',
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? '#8E9FB2' : '#2B2B2B',
      fontFamily: 'Inter-Medium',
      fontSize: '16px',
      lineHeight: '19px',
      padding: '6px 10px',
      backgroundColor: getAnimatedStyleOptionBgColor(state),
      boxSizing: 'border-box',
      cursor: 'pointer',
      ':active': {
        color: state.isDisabled ? '#8E9FB2' : '#2B2B2B',
        backgroundColor: state.isDisabled ? '#F6F6F6' : '#A7DFFF',
      },
      ':first-of-type': {
        paddingTop: '12px',
        paddingBottom: '6px',
      },
      ':last-child': {
        paddingTop: '6px',
        paddingBottom: '12px',
        border: 'none',
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      marginTop: '4px',
      paddingRight: '6px',
      borderRadius: '1px',
      boxShadow: '0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -5px rgba(24, 39, 75, 0.12)',
      boxSizing: 'border-box',
    }),
    menuList: (provided, state) => ({
      ...provided,
      ...menuScroll,
      padding: '0',
      paddingRight: '6px',
      boxSizing: 'border-box',
    }),
    control: (provided, state) => ({
      position: 'relative',
      display: 'flex',
      height: '100%',
      backgroundColor: state.isFocused ? '#F9F7FC' : '#FAF9FC',
      borderBottom: warning ? '1px solid #F33B2E' : '1px solid #C3D2E5',
      borderRadius: '1px 1px 0 0',
      boxShadow: 'unset',
      boxSizing: 'border-box',
      outline: '0 !important',
      transition: 'all 100ms',
      cursor: 'pointer',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: '#2B2B2B',
      fontFamily: 'Inter-Regular',
      fontSize: '16px',
      lineHeight: '19px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      position: 'absolute',
      right: state.selectProps.menuIsOpen ? '17px' : '15px',
      bottom: state.selectProps.menuIsOpen ? '7px' : '6px',
      display: state.isDisabled ? 'none' : 'flex',
      minWidth: '12px',
      width: '12px',
      minHeight: '12px',
      height: '12px',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      minWidth: '22px',
      width: '22px',
      minHeight: '22px',
      height: '22px',
      padding:' 0',
      color: '#2B2B2B',
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
      ':hover': {
        color: '#2B2B2B',
      },
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: '0 10px',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      top: 'unset',
      bottom: '6px',
      transform: 'unset',
      color: getAnimatedStyleSelectedColor(state),
      fontFamily: 'Inter-Medium',
      fontSize: '16px',
      lineHeight: '19px',
      padding: '0',
      margin: '0',
      ':hover': {
        color: state.isDisabled ? '#8E9FB2' : '#472575',
      },
    }),
    input: (provided, state) => ({
      margin: '0',
      paddingTop: '18px',
      boxSizing: 'border-box',
      '& input': {
        color: '#2B2B2B !important',
        fontFamily: 'Inter-Medium',
        fontSize: '16px !important',
        lineHeight: '19px',
      },
    }),
    noOptionsMessage: (provided, state) => ({
      padding: '6px 10px',
      color: '#8E9FB2',
      fontFamily: 'Inter-Medium',
      fontSize: '16px',
      lineHeight: '19px',
      textAlign: 'center',
      boxSizing: 'border-box',
    }),
  }
}

const CaretDownIcon = ({ disabled, menuIsOpen }) => {
  return (<div className={`selectbox__dropdown_icon ${disabled ? 'selectbox__dropdown_icon--disabled' : ''} ${menuIsOpen ? 'selectbox__dropdown_icon--focused' : ''}`}/>);
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon disabled={props.isDisabled} menuIsOpen={props.selectProps.menuIsOpen} />
    </components.DropdownIndicator>
  );
};

const getStyles = ({ animated, type, disabled, warning }) => {
  if (animated) return animatedStyles(disabled, warning);
  if (type === 'linechart') return customStyles({ type, disabled });
  if (type === 'table') return tableBoxStyles(disabled);
  
  return null;
}

const SelectBox = ({
  id,
  type,
  options = [],
  selected = null,
  placeholder = '',
  clearable = false,
  deleteRemoves = false,
  backspaceRemoves = false,
  searchable = false,
  menuPlacement,
  onSelectOpt = () => {},
  disabled = false,
  warning = false,
  warningMsg = '',
  animated = false,
}: Props) => {
  const [animationActive, setAnimationActive] = React.useState(false);

  return (
    <div className={`selectbox__wrapper ${animated ? 'selectbox__wrapper--animated' : ''}`}>
      <div className={`selectbox__main_wrapper ${animated ? 'selectbox__main_wrapper--animated' : ''}`}>
        <Select
          styles={getStyles({ animated, type, disabled, warning })}
          // components={animated ? { DropdownIndicator } : null}
          clearable={clearable}
          deleteRemoves={deleteRemoves}
          backspaceRemoves={backspaceRemoves}
          isSearchable={searchable}
          placeholder={placeholder}
          options={options}
          value={selected}
          isDisabled={disabled}
          menuPlacement={menuPlacement || 'auto'}
          onFocus={e => setAnimationActive(true)}
          onBlur={e => {
            if (selected && selected.value !== '') {
              e.preventDefault();
            } else {
              setAnimationActive(false)
            }
          }}
          onChange={value => onSelectOpt(value, id)}
        />
        {animated &&
          <label
            title={placeholder || ''}
            className={`selectbox__label ${(selected && selected.value !== '') ||
              animationActive ? 'selectbox__label--active' : ''} ${disabled &&
                selected &&
                selected.value !== '' ? 'selectbox__label--disabled_filled' : ''} ${warning ? 'selectbox__label--warning' : ''}`}
          >
            {placeholder || ''}
          </label>}
        {disabled &&
          animated &&
          <div className='selectbox__readonly_wrapper'>
            <div className='selectbox__readonly_icon' />
          </div>}
        <div className='selectbox__footer_wrapper'>
          {warning &&
            warningMsg &&
            <>
              <div className='selectbox__warning_icon' />
              <p className='selectbox__warning_msg'>{warningMsg}</p>
            </>}
          </div>
      </div>
    </div>
    )
}

export default SelectBox;