import Dashboard from './containers/Dashboard';
import Containers from './containers/Containers';
import Files from './containers/Files';
import Billing from './containers/Billing';
import Settings from './containers/Settings';
import FilesDetails from './containers/FilesDetails';
import Users from './containers/Users';

const routes = [
  {
    path: '/',
    element: Dashboard,
    sidebar: true,
  },
  {
    path: '/dashboard',
    element: Dashboard,
    sidebar: true,
  },
  {
    path: '/files',
    element: Files,
    sidebar: true,
  },
  {
    path: '/filesDetails/:id',
    element: FilesDetails,
    exact:true,
  },
  {
    path: '/account',
    element: Settings,
    sidebar: true,
  },
  {
    path: '/users',
    element: Users,
    sidebar: true,
  }
];

export default routes;