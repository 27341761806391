import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
let url = '';

if (process.env.NODE_ENV === 'development') {
  url =  'http://192.168.49.73:4444'; 
} else {
  url = '';
}

function parseJwt (token) {
  if (token) {
    var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
  }
  else {
    return null
  }
  
}

export const checkToken = () => {
  console.log('token from local storage:', parseJwt(localStorage.getItem('token')))
  return `Bearer ${localStorage.getItem('token')}` || null;
}

export const headerData = {
  'Content-Type': 'application/json-patch+json;charset=utf-8',
  'Accept': 'application/json-patch+json;charset=utf-8',
  'Cache-Control': `no-cache, no-store, must-revalidate`,
  'Pragma': 'no-cache',
  'skipAuthRefresh': true,
};

export const checkAvailableHeader = ({ auth }) => {
  if (auth) {
    return {
      ...headerData,
      Authorization: checkToken(),
    }
  }

  return headerData;
}

export const getUploadFileAuthorizedHeaders = () => ({
  headers: {
    ...checkAvailableHeader({ auth: true }),
    ['Content-Type']: 'multipart/form-data',
  },
});

export const getAuthorizedHeaders = () => ({
  headers: checkAvailableHeader({ auth: true }),
});

const dataForRefresh = () => {
  return {
    "grantType": "refresh_token",
    'refreshToken': localStorage.getItem('refreshToken'),
    'accessToken': localStorage.getItem('token'),
  }

}

export const Axiosinstance = axios.create({
  baseURL: url,
  headers: checkAvailableHeader({ auth: true })
});



// const api = new OpenAPIClientAxios({ definition: './petstore.yml' },headers:checkAvailableHeader({ auth: true }));
// const Axiosinstance = await api.init();


export const refreshAuthLogic = failedRequest => axios.post(url + '/api/oauth2/token', dataForRefresh(), headerData).then(tokenRefreshResponse => {
  localStorage.setItem('token', tokenRefreshResponse.data.accessToken);
  localStorage.setItem('refreshToken', tokenRefreshResponse.data.refreshToken);
  failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.accessToken;
  Axiosinstance.defaults.headers = checkAvailableHeader({ auth: true })
  return Promise.resolve();
}).catch((error) => {
  localStorage.setItem('isLogin', false);
  localStorage.setItem('token', '');
  localStorage.setItem('refreshToken', '');
  window.location.reload();
});

createAuthRefreshInterceptor(Axiosinstance, refreshAuthLogic);

export { url };
export const files = `/api/v1/files`;
export const deleteFiles = `/api/v1/files/deleteFiles`;
export const users = `/api/v1/accounts`;
export const deleteUsers = '/api/v1/Accounts/deleteUsers'
export const ui = `/api/v1/ui`;
export const settings = `${url}/api/v1/settings`;

