// @flow

import React, { useState } from 'react';

import './styles.scss';

type Props = {
  placeholder: string,
  onChange: (value: string) => void,
};

const Search = ({
  placeholder = '',
  onChange = () => {},
}: Props) => {
  const [inputValue, setInputValue] = useState('');
  
  return (
    <div className='search__container_wrapper'>
      <input
        type='input'
        className='search__input_wrapper'
        placeholder={placeholder}
        value={inputValue}
        onChange={e => {
          setInputValue(e.target.value);
          onChange(e.target.value);
        }}
      />
      {inputValue &&
        <button
          className='search__btn_clear_wrapper'
          onClick={() => {
            setInputValue('');
            onChange('');
          }}
        />}
    </div>
  )
};

export default Search;