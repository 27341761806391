import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { BrowserRouter } from 'react-router-dom';
import rootReducer from './store/reducers';
import './index.css';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';

const logger = createLogger();

const middlewares = [
  thunk,
];

process.env.NODE_ENV === 'development' && middlewares.push(logger);

const configureStore = () => {
  const store = createStore(rootReducer, compose(applyMiddleware(...middlewares)));

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./store/reducers', () => {
        store.replaceReducer(rootReducer);
      });
    }
  }

  return store;
};

const store = configureStore();

const render = Component => {
  return ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter  >
        <Component />
      </BrowserRouter>
    </Provider>,
    document.getElementById('root')
  );
};

render(App);

if (module.hot) {
  module.hot.accept('./containers/App', () => {
    const NextApp = require('./containers/App').default;
    render(NextApp);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
