// flow

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ModalWindow from '../ModalWindow';
import Input from '../Input';
import ButtonWizard from '../ButtonWizard';
import Checkbox from "../Checkbox";
import { ShowAlert } from '../Alert/AlertAction';

import locale from '../../locale';

import './styles.scss';

type Props = {
  onConfirm: (data: string) => void,
  onClose: () => void,
};

const CreateUserWizard = ({
  onConfirm,
  onClose,
}) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [login, setLogin] = useState('');
  const [ temp, setTemp] = useState(true);
  const dispatch = useDispatch();

  const onNameInputChange = (e) => {
    setName(e.target.value);
  }

  const onPassInputChange = (e) => {
    setPassword(e.target.value);
  }

  const onEmailInputChange = (e) => {
    setEmail(e.target.value);
  }

  const onLoginInputChange = (e) => {
    setLogin(e.target.value);
  }

  

  const onConfirmClick = () => {


    function validateEmail(email) {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailPattern.test(email);
    }

    function validateAlphanumeric(input) {
      const pattern = /^[a-zA-Z0-9]+$/;
      return pattern.test(input);
    }
    

    if (!name || !email || !password || !login) {
      dispatch(ShowAlert('warning', 'Please fill all fields', false, false));
      return;
    }

    if (!validateAlphanumeric(login)) {
      dispatch(ShowAlert('warning', 'Invalid login, allowed only letters and digits', false, false));
      return;
     }


    if (!validateEmail(email)) {
      dispatch(ShowAlert('warning', 'Invalid email', false, false));
      return;
     }

   

    if (name && email && password && login) {
        let obj = {
          userName: name,
          login:login,
          password: password, //SHA256
          email: email,
          isPasswordTemporary:temp,
          role: [ "buyer" ] // seller, buyer, admin or validator
      }
      console.log(obj)
      onConfirm(obj);
      
    }
  }

  return (
    <ModalWindow
      title={'Create user'}
      onBtnCloseClick={onClose}
    >
      <div className='create_user__wrapper'>
        <p className='create_user__label'>
         User name
        </p>
        <Input
          value={name}
          onChange={onNameInputChange}
        />
        <p className='create_user__label'>
         Login
        </p>
        <Input
          value={login}
          onChange={onLoginInputChange}
        />
        <p className='create_user__label'>
         Email
        </p>
        <Input
          value={email}
          onChange={onEmailInputChange}
        />
        <p className='create_user__label'>
         Password
        </p>
        <Input
          value={password}
          type="password"
          showPassBtn
          onChange={onPassInputChange}
        />
        <div className="checkbox-temp-wrapper">
        <label className='temp__checkbox_label_wrapper'>
            <Checkbox
              name='remember credentials checkbox'
              id='remember_cred'
              checked={temp}
              onChange={()=> setTemp(!temp)}
              additionalClassName={'login__checkbox_style'}
            />
            <p className='temp__checkbox_label_text'>Set password as temporary</p>
          </label>
          </div>
        <div className='create_user__btn_wrapper'>
          <div className='create_user__btn'>
            <ButtonWizard
              caption={locale('IDS_COMMON_BTN_CAPTION_CLOSE')}
              onClick={onClose}
            />
          </div>
          <div className='create_user__btn'>
            <ButtonWizard
              caption={'Create'}
              active
              onClick={onConfirmClick}
            />
          </div>
        </div>
      </div>
    </ModalWindow>
  )
}

export default CreateUserWizard;
