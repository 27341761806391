import * as types from '../actions/actionTypes';

const initialState = {
  selectedRow: null,
}

const table = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_TABLE_SELECTED_ROW_DATA:
      return {...state, selectedRow: action.payload };
    default:
      return state;
  }
}

export default table;