export const stringToLowerCase = (str) => {
  if (typeof str === 'string') return str.toLowerCase();

  return '';
}

export const reducer = (accumulator, currentValue) => {
  if (accumulator === null && currentValue === null) {
    return null;
  } else {
    return accumulator + currentValue;
  }
};
