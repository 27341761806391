// flow

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ModalWindow from '../ModalWindow';
import Input from '../Input';
import ButtonWizard from '../ButtonWizard';
import Checkbox from "../Checkbox";
import { ShowAlert } from '../Alert/AlertAction';
import {resetUserPassword} from '../../store/actions'

import locale from '../../locale';

import './styles.scss';

type Props = {
  onClose: () => void,
};

const ResetUserPasswordWizard = ({
  user,
  onClose,
}) => {
  
  const dispatch = useDispatch();

  const [password, setPassword] = useState('');
  
  const onConfirmClick = () => {
    dispatch(resetUserPassword(user.id)).then((res)=>{
      if (res) {
        setPassword(res)
      }
    })
  }

  const copyPaste = (name ) => {
   
    navigator.clipboard.writeText(password).then(
      function () {
        console.log('Async: Copying to clipboard was successful!');
        
      },
      function (err) {
        console.error('Async: Could not copy text: ', err);
      }
    );
  };

  return (
    <ModalWindow
      title={'Reset user password'}
      onBtnCloseClick={onClose}
    >
      <div className='reset_user__password__wrapper'>
        
        <div className="checkbox-temp-wrapper">

          <div className='copy-field'>{password}</div>
          <button onClick={copyPaste} className='copy-icon'></button>
        
          </div>
        <div className='reset_user__btn_wrapper'>
          <div className='reset_user__btn'>
            <ButtonWizard
              caption={locale('IDS_COMMON_BTN_CAPTION_CLOSE')}
              onClick={onClose}
            />
          </div>
          <div className='reset_user__btn'>
            <ButtonWizard
              caption={'Create'}
              active
              onClick={onConfirmClick}
            />
          </div>
        </div>
      </div>
    </ModalWindow>
  )
}

export default ResetUserPasswordWizard;
